import Vue from "vue";
import store from "./store.js";
import Router from "vue-router";
import Home from "./views/general/Home.vue";
import Login from "./views/general/Login.vue";
import Reports from "./views/reports/Reports.vue";
import UserBank from "./views/users/UserBank.vue";
import Calendar from "./views/general/Calendar.vue";
import Settings from "./views/general/Settings.vue";
import ListUsers from "./views/users/ListUsers.vue";
import Finances from "./views/finances/Finances.vue";
import ManageUser from "./views/users/ManageUser.vue";
import CreateUser from "./views/users/CreateUser.vue";
import Dashboard from "./views/general/Dashboard.vue";
import ListAssets from "./views/assets/ListAssets.vue";
import Campaigns from "./views/campaigns/general/Campaigns.vue";
import CreateAsset from "./views/assets/CreateAsset.vue";
import ManageAsset from "./views/assets/ManageAsset.vue";
import Withdrawals from "./views/finances/Withdrawals.vue";
import PageNotFound from "./views/general/PageNotFound.vue";
import ResetPassword from "./views/general/ResetPassword.vue";
import UserWithdrawals from "./views/users/UserWithdrawals.vue";
import SoldCampaigns from "./views/campaigns/general/SoldCampaigns.vue";
import CreateCampaign from "./views/campaigns/general/CreateCampaign.vue";
import ManageCampaign from "./views/campaigns/micro/ManageCampaign.vue";
import ManageAdlinkCampaign from "./views/campaigns/adlink/ManageAdlinkCampaign.vue";
import ManageFixedCampaign from "./views/campaigns/fixed/ManageFixedCampaign.vue";
import WithdrawalHistory from "./views/users/WithdrawalHistory.vue";
import ListMicroCampaigns from "./views/campaigns/micro/ListMicroCampaigns.vue";
import ListAdlinkCampaigns from "./views/campaigns/adlink/ListAdlinkCampaigns.vue";
import ListFixedCampaigns from "./views/campaigns/fixed/ListFixedCampaigns.vue";
import ActiveMicroCampaigns from "./views/campaigns/micro/ActiveMicroCampaigns.vue";
import ArchivedMicroCampaigns from "./views/campaigns/micro/ArchivedMicroCampaigns.vue";
import AvailableMicroCampaigns from "./views/campaigns/micro/AvailableMicroCampaigns.vue";
import AvailableAdlinkCampaigns from "./views/campaigns/adlink/AvailableAdlinkCampaigns.vue";
import ActiveFixedCampaigns from "./views/campaigns/fixed/ActiveFixedCampaigns.vue";
import ArchivedFixedCampaigns from "./views/campaigns/fixed/ArchivedFixedCampaigns.vue";
import ConversionReport from "./views/reports/adlinks/ConversionReport.vue";
import RevenueStatusReport from "./views/reports/adlinks/RevenueStatusReport.vue";
import AdlinkGeneralReport from "./views/reports/adlinks/GeneralReport.vue";
import AdlinkGeneralReportPivot from "./views/reports/adlinks/GeneralReportPivot.vue";
import ProfileFixedCampaign from "./views/campaigns/fixed/ProfileFixedCampaign.vue";
import ShortlinkReport from "./views/reports/adlinks/ShortlinkReport.vue";
import Applications from "./views/general/Applications.vue";
import FinanceReports from "./views/cube/reports/FinanceReports.vue";
import AddTransaction from "./views/cube/finance/transactions/AddTransaction.vue";
import ListTransactions from "./views/cube/finance/transactions/ListTransactions.vue";
import FixedGeneralReport from "./views/reports/fixed/GeneralReport.vue";
import MoneyList from "./views/finances/MoneyList.vue";
import DetailedMoneyList from "./views/finances/DetailedMoneyList.vue";
import AdlinkOverview from "./views/finances/AdlinkOverview.vue";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "home",
      component: Home,
      meta: { noMargin: true }
    },
    {
      path: "/campaigns",
      name: "campaigns",
      component: Campaigns,
      beforeEnter: (to, from, next) => {
        if(store.getters.isLoggedIn === true) {
          next();
        } else {
          next('/dashboard')
        }
      }
    },
    {
      path: "/reports",
      name: "reports",
      component: Reports,
      beforeEnter: (to, from, next) => {
        if(store.getters.isLoggedIn === true) {
          next();
        } else {
          next('/dashboard')
        }
      }
    },
    {
      path: "/login",
      name: "login",
      component: Login
    },
    {
      path: "/resetpassword",
      name: "resetpassword",
      component: ResetPassword,
      props: true,
      meta: { noMargin: true },
      beforeEnter: (to, from, next) => {
        if( to.query.token != undefined && to.query.email != undefined ) {
          next();
        } else {
          next('/');
        }
      }
    },
    {
      path: "/dashboard",
      name: "dashboard",
      component: Dashboard
    },
    {
      path: "/calendar",
      name: "calendar",
      component: Calendar,
      beforeEnter: (to, from, next) => {
        if(store.getters.isLoggedIn === true) {
          next();
        } else {
          next('/dashboard')
        }
      }
    },
    {
      path: "/settings",
      name: "settings",
      component: Settings,
      beforeEnter: (to, from, next) => {
        if(store.getters.isLoggedIn === true) {
          next();
        } else {
          next('/dashboard')
        }
      }
    },
    {
      path: "/create-campaign",
      name: "createcampaign",
      component: CreateCampaign,
      beforeEnter: (to, from, next) => {
        if(store.getters.level === 1 || store.getters.level === 2) {
          next();
        } else {
          next('/dashboard')
        }
      }
    },
    {
      path: "/list-users",
      name: "listusers",
      component: ListUsers,
      props: true,
      beforeEnter: (to, from, next) => {
        if(store.getters.type === 1) {
          next();
        } else {
          next('/dashboard')
        }
      }
    },
    {
      path: "/list-microcampaigns",
      name: "listmicrocampaigns",
      component: ListMicroCampaigns,
      props: true,
      beforeEnter: (to, from, next) => {
        if(store.getters.isLoggedIn === true) {
          next();
        } else {
          next('/dashboard')
        }
      }
    },
    {
      path: "/list-adlinkcampaigns",
      name: "listadlinkcampaigns",
      component: ListAdlinkCampaigns,
      props: true,
      beforeEnter: (to, from, next) => {
        if(store.getters.isLoggedIn === true) {
          next();
        } else {
          next('/dashboard')
        }
      }
    },
    {
      path: "/list-fixedcampaigns",
      name: "listfixedcampaigns",
      component: ListFixedCampaigns,
      props: true,
      beforeEnter: (to, from, next) => {
        if(store.getters.isLoggedIn === true) {
          next();
        } else {
          next('/dashboard')
        }
      }
    },
    {
      path: "/manage-user",
      name: "manageuser",
      component: ManageUser,
      props: true,
      beforeEnter: (to, from, next) => {
        if(store.getters.isLoggedIn === true) {
          next();
        } else {
          next('/dashboard')
        }
      }
    },
    {
      path: "/create-user",
      name: "createuser",
      component: CreateUser,
      props: true,
      beforeEnter: (to, from, next) => {
        if(store.getters.level === 1 || store.getters.level === 2) {
          next();
        } else {
          next('/dashboard')
        }
      }
    },
    {
      path: "/create-asset",
      name: "createasset",
      component: CreateAsset,
      props: true,
      beforeEnter: (to, from, next) => {
        if(store.getters.level === 1 || store.getters.level === 2) {
          next();
        } else {
          next('/dashboard')
        }
      }
    },
    {
      path: "/list-assets",
      name: "listassets",
      component: ListAssets,
      props: true,
      beforeEnter: (to, from, next) => {
        if(store.getters.level === 1 || store.getters.level === 2) {
          next();
        } else {
          next('/dashboard')
        }
      }
    },
    {
      path: "/manage-asset",
      name: "manageasset",
      component: ManageAsset,
      props: true,
      beforeEnter: (to, from, next) => {
        if(store.getters.level === 1 || store.getters.level === 2) {
          next();
        } else {
          next('/dashboard')
        }
      }
    },
    {
      path: "/available-microcampaigns",
      name: "availablemicrocampaigns",
      component: AvailableMicroCampaigns,
      props: true,
      beforeEnter: (to, from, next) => {
        if(store.getters.isLoggedIn === true) {
          next();
        } else {
          next('/dashboard')
        }
      }
    },
    {
      path: "/available-adlinkcampaigns",
      name: "availableadlinkcampaigns",
      component: AvailableAdlinkCampaigns,
      props: true,
      beforeEnter: (to, from, next) => {
        if(store.getters.isLoggedIn === true) {
          next();
        } else {
          next('/dashboard')
        }
      }
    },
    {
      path: "/active-fixedcampaigns",
      name: "activefixedcampaigns",
      component: ActiveFixedCampaigns,
      props: true,
      beforeEnter: (to, from, next) => {
        if(store.getters.isLoggedIn === true) {
          next();
        } else {
          next('/dashboard')
        }
      }
    },
    {
      path: "/archived-fixedcampaigns",
      name: "archivedfixedcampaigns",
      component: ArchivedFixedCampaigns,
      props: true,
      beforeEnter: (to, from, next) => {
        if(store.getters.isLoggedIn === true) {
          next();
        } else {
          next('/dashboard')
        }
      }
    },
    {
      path: "/active-microcampaigns",
      name: "activemicrocampaigns",
      component: ActiveMicroCampaigns,
      props: true,
      beforeEnter: (to, from, next) => {
        if(store.getters.isLoggedIn === true) {
          next();
        } else {
          next('/dashboard')
        }
      }
    },
    {
      path: "/archived-microcampaigns",
      name: "archivedmicrocampaigns",
      component: ArchivedMicroCampaigns,
      props: true,
      beforeEnter: (to, from, next) => {
        if(store.getters.isLoggedIn === true) {
          next();
        } else {
          next('/dashboard')
        }
      }
    },
    {
      path: "/manage-campaign",
      name: "managecampaign",
      component: ManageCampaign,
      props: true,
      beforeEnter: (to, from, next) => {
        if(store.getters.isLoggedIn === true) {
          next();
        } else {
          next('/dashboard')
        }
      }
    },
    {
      path: "/manage-adlinkcampaign",
      name: "manageadlinkcampaign",
      component: ManageAdlinkCampaign,
      props: true,
      beforeEnter: (to, from, next) => {
        if(store.getters.isLoggedIn === true) {
          next();
        } else {
          next('/dashboard')
        }
      }
    },
    {
      path: "/manage-fixedcampaign",
      name: "managefixedcampaign",
      component: ManageFixedCampaign,
      props: true,
      beforeEnter: (to, from, next) => {
        if(store.getters.isLoggedIn === true) {
          next();
        } else {
          next('/dashboard')
        }
      }
    },
    {
      path: "/profile-fixedcampaign",
      name: "profilefixedcampaign",
      component: ProfileFixedCampaign,
      props: true,
      beforeEnter: (to, from, next) => {
        if(store.getters.type === 1 || store.getters.type === 2) {
          next();
        } else {
          next('/dashboard')
        }
      }
    },
    {
      path: "/user-bank",
      name: "userbank",
      component: UserBank,
      props: true,
      beforeEnter: (to, from, next) => {
        if(store.getters.type === 1 || store.getters.type === 2) {
          next();
        } else {
          next('/dashboard')
        }
      }
    },
    {
      path: "/withdrawals",
      name: "withdrawals",
      component: Withdrawals,
      props: true,
      beforeEnter: (to, from, next) => {
        if(store.getters.type === 1 || store.getters.type === 2) {
          next();
        } else {
          next('/dashboard')
        }
      }
    },
    {
      path: "/user-withdrawals",
      name: "userwithdrawals",
      component: UserWithdrawals,
      props: true,
      beforeEnter: (to, from, next) => {
        if(store.getters.type === 1 || store.getters.type === 2) {
          next();
        } else {
          next('/dashboard')
        }
      }
    },
    {
      path: "/finances",
      name: "finances",
      component: Finances,
      props: true,
      beforeEnter: (to, from, next) => {
        if(store.getters.level === 1 || store.getters.level === 3 || store.getters.type === 2) {
          next();
        } else {
          next('/dashboard')
        }
      }
    },
    {
      path: "/withdrawal-history",
      name: "withdrawalhistory",
      component: WithdrawalHistory,
      props: true,
      beforeEnter: (to, from, next) => {
        if(store.getters.type === 1 || store.getters.type === 2) {
          next();
        } else {
          next('/dashboard')
        }
      }
    },
    {
      path: "/sold-campaigns",
      name: "soldcampaigns",
      component: SoldCampaigns,
      props: true,
      beforeEnter: (to, from, next) => {
        if(store.getters.isLoggedIn === true) {
          next();
        } else {
          next('/dashboard')
        }
      }
    },
    {
      path: "/conversion-report",
      name: "conversionreport",
      component: ConversionReport,
      props: true,
      beforeEnter: (to, from, next) => {
        if(store.getters.isLoggedIn === true) {
          next();
        } else {
          next('/dashboard')
        }
      }
    },
    {
      path: "/revenue-status-report",
      name: "revenuestatusreport",
      component: RevenueStatusReport,
      props: true,
      beforeEnter: (to, from, next) => {
        if(store.getters.type === 1 || store.getters.type === 2) {
          next();
        } else {
          next('/dashboard')
        }
      }
    },
    {
      path: "/adlink-general-report",
      name: "adlinkgeneralreport",
      component: AdlinkGeneralReport,
      props: true,
      beforeEnter: (to, from, next) => {
        if(store.getters.isLoggedIn === true) {
          next();
        } else {
          next('/dashboard')
        }
      }
    },
    {
      path: "/adlink-general-report-pivot",
      name: "adlinkgeneralreportpivot",
      component: AdlinkGeneralReportPivot,
      props: true,
      beforeEnter: (to, from, next) => {
        if(store.getters.isLoggedIn === true) {
          next();
        } else {
          next('/dashboard')
        }
      }
    },
    {
      path: "/fixed-general-report",
      name: "fixedgeneralreport",
      component: FixedGeneralReport,
      props: true,
      beforeEnter: (to, from, next) => {
        if(store.getters.isLoggedIn === true) {
          next();
        } else {
          next('/dashboard')
        }
      }
    },
    {
      path: "/shortlink-report",
      name: "shortlinkreport",
      component: ShortlinkReport,
      props: true,
      beforeEnter: (to, from, next) => {
        if(store.getters.type === 1 || store.getters.type === 2) {
          next();
        } else {
          next('/dashboard')
        }
      }
    },
    {
      path: "/applications",
      name: "applications",
      component: Applications,
      props: true,
      beforeEnter: (to, from, next) => {
        if(store.getters.level === 1) {
          next();
        } else {
          next('/dashboard')
        }
      }
    },
    {
      path: "/finance-reports",
      name: "financereports",
      component: FinanceReports,
      props: true,
      beforeEnter: (to, from, next) => {
        if(store.getters.level === 1) {
          next();
        } else {
          next('/dashboard')
        }
      }
    },
    {
      path: "/addtransaction",
      name: "addtransaction",
      component: AddTransaction,
      props: true,
      beforeEnter: (to, from, next) => {
        if(store.getters.type === 1) {
          next();
        } else {
          next('/dashboard')
        }
      }
    },
    {
      path: "/list-transactions",
      name: "listtransactions",
      component: ListTransactions,
      props: true,
      beforeEnter: (to, from, next) => {
        if(store.getters.type === 1) {
          next();
        } else {
          next('/dashboard')
        }
      }
    },
    {
      path: "/moneylist",
      name: "moneylist",
      component: MoneyList,
      props: true,
      beforeEnter: (to, from, next) => {
        if(store.getters.level === 1 || store.getters.level === 3) {
          next();
        } else {
          next('/dashboard')
        }
      }
    },
    {
      path: "/detailedmoneylist",
      name: "detailedmoneylist",
      component: DetailedMoneyList,
      props: true,
      beforeEnter: (to, from, next) => {
        if(store.getters.level === 1 || store.getters.level === 3) {
          next();
        } else {
          next('/dashboard')
        }
      }
    },
    {
      path: "/adlinkoverview",
      name: "adlinkoverview",
      component: AdlinkOverview,
      props: true,
      beforeEnter: (to, from, next) => {
        if(store.getters.level === 1 || store.getters.level === 3) {
          next();
        } else {
          next('/dashboard')
        }
      }
    },
    {
      path: '*',
      nane: 'pagenotfound',
      component: PageNotFound
    },
  ]
});


router.beforeEach((to, from, next) => {
  if (store.getters.hasUpdated){
    next();
  } else {
    next();
  }
});

export default router;
