<template>
<div class="availablemicrocampaigns">
  <b-row>
    <b-col cols="12" class="header">
      <h1>Adlink Campaigns</h1>
      <b-breadcrumb :items="breadcrumbs"></b-breadcrumb>
    </b-col>
  </b-row>
    <b-row class="margin-bottom">
      <b-col md="8" class="margin-bottom sortable">
        <b-button @click="toggleSortables('1')" id="sort-1" class="active-sort" variant="outline-primary">Brand</b-button>

        <b-button @click="toggleSortables('2')" id="sort-2" variant="outline-primary">Newest</b-button>

        <b-button @click="toggleSortables('3')" id="sort-3" variant="outline-primary">CPA</b-button>

        <b-button @click="toggleSortables('4')" id="sort-4" variant="outline-primary">CPC</b-button>

      </b-col>
      <b-col md="4" class="margin-bottom">
        <b-form-input v-model="search" @input="toggleSortables('search');" placeholder="Search for brand"></b-form-input>
      </b-col>
    </b-row>
    <b-row>
      <b-col xl="4" lg="4" md="6" v-for="item in orderedItems(items)" v-bind:key="item.id">
        <b-modal :id="'modal_'+item.id" title="Description" hide-footer>
          <p>
            {{ item.description }}
          </p>
        </b-modal>
        <b-modal :id="'modal_link_'+item.id" title="Create link" hide-footer>
            <b-form-group
                v-if="show_generator == true"
                id="input-group-1"
                label-for="input-1"
                description="Enter the URL to the product"
            >
              <b-form-input
                id="input-1"
                v-model="link"
                type="url"
                required
                placeholder="https://"
              ></b-form-input>
            </b-form-group>


            <b-button v-if="show_generator == true"
            :id="'generate_link_-'+item.id" block variant="outline-primary"
            @click="createShortLink(item.id, item.tune_id, item.brand_id,
            item.network)">Generate link</b-button>

            <div v-if="show_link == true" class="text-center">
                <h6>Your generated link</h6>
                <h5>{{ short_link }}</h5>
            </div>
        </b-modal>
        <b-card
            overlay
            :img-src="getCampaignImg(item.id)"
            @error="replaceByDefault"
            text-variant="white"
        >

        <b-row>
          <b-col cols="12" class="text-center">
            <h3>{{ item.campaign_name }}</h3>
            <b-img
              class="campaign-img"
              :src="getBrandImg(item.brand_id)"
              fluid
            ></b-img>
            <h5>{{ item.company_name }}</h5>

            <h1 class="payout-type" v-if="item.payout_type == 'cpc'">{{ $global.convertCurrency(country, item.profile_cpc) }}</h1>
            <h1 class="payout-type" v-if="item.payout_type == 'cpa_percentage'">{{ item.profile_payout_percent }}%</h1>
          </b-col>
        </b-row>
        <b-row class="foot">
          <b-col xl="6" lg="12">
            <b-button :id="'createlink_'+item.id" variant="outline-light" block @click="openModal(item.id)">Create link</b-button>
          </b-col>
          <b-col xl="6" lg="12">
            <b-button :id="'viewbrief_'+item.id" variant="outline-light" block @click="$bvModal.show('modal_'+item.id+'')">Description</b-button>
          </b-col>
        </b-row>

        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import $ from 'jquery'
  import { mapState } from 'vuex'


  export default {
    name: "availablemicrocampaigns",
    components: {
    },
    computed: {
         ...mapState(['profile_assets', 'current_id', 'profile_id', 'country']),
    },
    data: function () {
      return {
        items: [],
        show: false,
        currentOrder: 'company_name',
        link: '',
        short_link: '',
        show_link: false,
        show_generator: true,
        search: '',
        breadcrumbs: [
        {
          text: 'Dashboard',
          to: '/dashboard'
        },
        {
          text: 'Campaigns',
          to: '/campaigns'
        },
        {
          text: 'Adlink Campaigns',
          active: true
        },
      ],
      }
    },
    created () {
      this.listCampaigns()
    },
    methods: {
      getCampaignImg(id){
        return this.$global.spacesURL()+'campaigns/adlink/campaign_' + id + '/cover_image/campaign_' + id + '.png'
      },
      getBrandImg(brand_id){
            return this.$global.spacesURL()+'brands/brand_' + brand_id + '/profile_picture/brand_' + brand_id + '.png'
      },
      toggleSortables(id){
        if(id == 1) {
          this.currentOrder = 'company_name'
          $("#sort-1").addClass('active-sort')
          $("#sort-2").removeClass('active-sort')
          $("#sort-3").removeClass('active-sort')
          $("#sort-4").removeClass('active-sort')
        } else if (id == 2) {
          this.currentOrder = 'id'
          $("#sort-1").removeClass('active-sort')
          $("#sort-2").addClass('active-sort')
          $("#sort-3").removeClass('active-sort')
          $("#sort-4").removeClass('active-sort')
        } else if (id == 3) {
          this.currentOrder = 'profile_payout_percent'
          $("#sort-1").removeClass('active-sort')
          $("#sort-2").removeClass('active-sort')
          $("#sort-3").addClass('active-sort')
          $("#sort-4").removeClass('active-sort')
        } else if (id == 4) {
          this.currentOrder = 'profile_cpc'
          $("#sort-1").removeClass('active-sort')
          $("#sort-2").removeClass('active-sort')
          $("#sort-3").removeClass('active-sort')
          $("#sort-4").addClass('active-sort')
        } else if (id == 'search') {
          this.currentOrder = 'search'
          $("#sort-1").addClass('active-sort')
          $("#sort-2").removeClass('active-sort')
          $("#sort-3").removeClass('active-sort')
          $("#sort-4").removeClass('active-sort')
        }
      },
      replaceByDefault(e){
        var url = this.$global.spacesURL()+"brands/brand_" + e.target.id + "/profile_picture/brand_" + e.target.id + ".png"

        e.target.src = url
      },
      orderedItems() {

          if(this.currentOrder == 'company_name') {
            var defaultItems = _.filter(this.items, ['default', 1]);
            return _.orderBy(defaultItems, this.currentOrder, 'asc')
          } else if(this.currentOrder == 'profile_payout_percent') {
            var defaultItems = _.filter(this.items, ['revenue_type', 'cpa_percentage']);
            return _.orderBy(defaultItems, this.currentOrder, 'desc')
          } else if(this.currentOrder == 'profile_cpc') {
            var defaultItems = _.filter(this.items, ['revenue_type', 'cpc']);
            return _.orderBy(defaultItems, this.currentOrder, 'desc')
          } else if(this.currentOrder == 'search') {

            if(this.search != ''){
              var defaultItems = _.filter(
                this.items, (item) =>  {
                  if(_.includes(item.company_name.toLowerCase(), this.search.toLowerCase())) {
                    return item
                  }
                }
              )
            } else {
              var defaultItems = _.filter(this.items, ['default', 1]);
            }

            return _.orderBy(defaultItems, this.currentOrder, 'desc')
          } else {
            return _.orderBy(this.items, this.currentOrder, 'desc')
          }

      },
      listCampaigns() {
        this.$http.bamse.post('campaigns/adlink/listall/profile/self', {
            profile_id: this.profile_assets[this.$global.getProfileInfo(this.profile_id, this.profile_assets)].profile_id
        })
          .then(response => this.listCampaignsSuccess(response))
          .catch(() => this.listCampaignsFailed())
      },
      listCampaignsSuccess(response) {
        if(response.data.success) {
          this.items = response.data.success
          this.items = _.uniqBy(this.items, 'id');

        }
      },
      listCampaignsFailed() {
        this.error = 'no fetch sorry'
      },
      createShortLink(campaign_id, campaign_tune_id, brand_id, network) {

          let link;

          if(this.link.includes("?")) {
            link = this.link+"&utm_source=revrelations&utm_medium=affiliate"
          }
          if(!this.link.includes("?")) {
            link = this.link+"?utm_source=revrelations&utm_medium=affiliate"
          }

          this.$http.bamse.post('campaigns/adlink/shortlink/create', {
            full_url: link,
            profile_id: this.profile_assets[this.$global.getProfileInfo(this.profile_id, this.profile_assets)].profile_id,
            brand_id: brand_id,
            user_id: this.current_id,
            profile_tune_id: this.profile_assets[this.$global.getProfileInfo(this.profile_id, this.profile_assets)].tune_id,
            campaign_tune_id: campaign_tune_id,
            campaign_id: campaign_id,
            network: network
          })
          .then(response => this.createShortLinkSuccess(response))
          .catch(() => this.createShortLinkFailed())
      },
      createShortLinkSuccess(response) {
        if(response.data.success) {

          if(process.env.VUE_APP_MODE === "local") {
            this.short_link = process.env.VUE_APP_TRACKER_URL_DEV+response.data.success
          } else {
            this.short_link = process.env.VUE_APP_TRACKER_URL+response.data.success
          }


          this.show_link = true
          this.show_generator = false

        }
      },
      createShortLinkFailed() {
        this.error = 'no fetch sorry'
      },
      openModal(id){
          this.show_link = false
          this.show_generator = true
          this.link = ''
          this.$bvModal.show('modal_link_'+id+'')
      }
    }
  };
</script>
