<template>
  <div class="campaign-info campaign-reports">
    <b-row>
      <b-col cols="12" class="header">
        <h1>Performance Breakdown</h1>
        <b-breadcrumb :items="breadcrumbs"></b-breadcrumb>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <b-card class="campaign-reports-box">
          <div>
            <b-form>
              <b-row v-if="showdates">
                <b-col lg="2">
                  <b-form-group label-size="sm" label="Start date" label-for="start_date">
                    <datepicker
                      wrapper-class="form-control"
                      :monday-first="true"
                      format="dd-MM-yyyy"
                      v-model="start_date"
                      name="start_date"
                    ></datepicker>
                  </b-form-group>
                </b-col>
                <b-col lg="2">
                  <b-form-group label-size="sm" label="End date" label-for="end_date">
                    <datepicker
                      wrapper-class="form-control"
                      :monday-first="true"
                      format="dd-MM-yyyy"
                      v-model="end_date"
                      name="end_date"
                    ></datepicker>
                  </b-form-group>
                </b-col>
                <b-col lg="3">
                  <b-button @click="updateGraph()" class="pull-left active-sort getdatesbtn" variant="outline-primary">Submit</b-button>
                   <b-button @click="showdates = false" class="pull-left active-sort getdatesbtn" variant="outline-primary">Cancel</b-button>
                </b-col>
                <b-col xl="5" class="datefilter">
                   <b-button @click="pickDates(3)" class="pull-left active-sort getdatesbtn" variant="outline-primary">Last 90 days</b-button>

                   <b-button @click="pickDates(2)" class="pull-left active-sort getdatesbtn" variant="outline-primary">Last 30 days</b-button>

                  <b-button @click="pickDates(1)" class="pull-left active-sort getdatesbtn" variant="outline-primary">Last 7 days</b-button>
                </b-col>
              </b-row>
              <b-row v-else>
                <b-col lg="3" class="margin-bottom-sort">
                  <span class="label">Current dates</span>
                  <b-button @click="showdates = true" v-if="loading == false" class="active-sort datebtn" variant="outline-primary">{{ start_date}} - {{ end_date }}</b-button>
                  <b-button class="active-sort datebtn" variant="outline-primary" v-else>
                    <b-spinner small ></b-spinner> Loading
                  </b-button>
                </b-col>
                <b-col lg="3">
                  <span class="label">Brands</span>
                  <multiselect
                    :showLabels="false"
                    placeholder="Pick brands"
                    :close-on-select="false"
                    multiple
                    :clearOnSelect="false"
                    v-model="brand_array"
                    :options="brand_options"
                    label="text"
                    track-by="text"
                  >
                    <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length }} brands selected</span></template>
                  </multiselect>
                </b-col>
                <b-col lg="3">
                  <span class="label">Profiles</span>
                  <multiselect
                    :showLabels="false"
                    placeholder="Pick profiles"
                    :close-on-select="false"
                    multiple
                    :clearOnSelect="false"
                    v-model="profiles_array"
                    :options="profiles_options"
                    label="text"
                    track-by="text"
                  >
                    <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length }} profiles selected</span></template>
                  </multiselect>
                </b-col>
                <b-col lg="3">
                  <span class="label">Campaigns</span>
                  <multiselect
                    :showLabels="false"
                    placeholder="Pick campaigns"
                    :close-on-select="false"
                    multiple
                    :clearOnSelect="false"
                    v-model="campaign_array"
                    :options="campaign_options"
                    label="text"
                    track-by="text"
                  >
                  <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length }} campaigns selected</span></template>
                  </multiselect>
                </b-col>
                <b-col cols="12" class="sortfilter">
                   <b-button @click="pickFilter(1)" class="pull-left active-sort getdatesbtn" variant="outline-primary">Filter 1</b-button>

                   <b-button @click="pickFilter(2)" class="pull-left active-sort getdatesbtn" variant="outline-primary">Filter 2</b-button>

                  <b-button @click="pickFilter(3)" class="pull-left active-sort getdatesbtn" variant="outline-primary">Filter 3</b-button>

                  <b-button @click="updateGraph()" class="pull-right active-sort getdatesbtn updategraph" variant="outline-primary">Update</b-button>
                </b-col>
              </b-row>
            </b-form>

            <hr class="reporthr">

            <b-row class="margin-bottom">
              <b-col cols="12" class="margin-bottom sortable">
                <b-button @click="filterChart('all')" id="show-all" class="active-sort" variant="outline-primary">Show all</b-button>
                <b-button @click="filterChart('hideall')" id="hide-all" class="hide-all" variant="outline-primary">Hide all</b-button>
                
                <b-button 
                  v-if="type == 1"
                  :id="item.value" 
                  @click="filterChart(item.value)" 
                  v-for="item in buttons" 
                  variant="outline-primary" 
                  class="active-sort" 
                  :style="'background-color:#000; color: #fff;'"
                >
                  {{ item.label }}
                </b-button>
                <b-button 
                  v-if="type > 1"
                  :id="item.value" 
                  @click="filterChart(item.value)" 
                  v-for="item in buttons_profile" 
                  variant="outline-primary" 
                  class="active-sort" 
                  :style="'background-color:#000; color: #fff;'"
                >
                  {{ item.label }}
                </b-button>
                
              
              </b-col>
            </b-row>
          </div>
          <span class="label">Sort by</span>
          <b-form-select
            v-model="pivot_selected"
            :options="pivot_options"
            @change="updatePivot"
          >
          </b-form-select>
          <hr>
          <div class="pivot-responsive">
            <b-table
              striped
              hover
              responsive
              :items="items"
              :fields="this.type == 1 ? fields : fields_profile"
              ref="pivotTable"
              foot-clone
            >
              <template v-slot:cell(revenue)="row">
                {{ $global.convertCurrency(country, row.item.revenue)}}
              </template>
              <template v-slot:cell(net_payout)="row">
                {{ $global.convertCurrency(country, row.item.net_payout)}}
              </template>
              <template v-slot:cell(profit)="row">
                {{ $global.convertCurrency(country, row.item.profit)}}
              </template>
              <template v-slot:cell(sale_amount)="row">
                {{ $global.convertCurrency(country, row.item.sale_amount)}}
              </template>
              <template v-slot:cell(margin)="row">
                {{ row.item.margin }} %
              </template>
              <template v-slot:cell(cr)="row">
                {{ row.item.cr }} %
              </template>
              <template v-slot:cell(aov)="row">
                {{ $global.convertCurrency(country, row.item.aov)}}
              </template>
              <template v-slot:cell(cos)="row">
                {{ row.item.cos * 100 }} %
              </template>
              <template v-slot:cell(roi)="row">
                {{ $global.convertCurrency(country, row.item.roi)}}
              </template>
              <template v-slot:cell(roi)="row">
                {{ $global.convertCurrency(country, row.item.roi)}}
              </template>
              <template v-slot:cell(net_sale_amount)="row">
                {{ $global.convertCurrency(country, row.item.net_sale_amount)}}
              </template>
              <template #foot(profile)="data">
                <span></span>
              </template>
              <template #foot(cr)="data">
                <span></span>
              </template>
              <template #foot(cos)="data">
                <span></span>
              </template>
              <template #foot(margin)="data">
                <span></span>
              </template>
              <template #foot(clicks)="data">
                {{ totalclicks }}
              </template>
              <template #foot(unique_clicks)="data">
                {{ totaluniqueclicks }}
              </template>
              <template #foot(conversions)="data">
                {{ totalconversions }}
              </template>
              <template #foot(revenue)="data">
                {{ $global.convertCurrency(country, totalrevenue)}}
              </template>
              <template #foot(net_payout)="data">
                {{ $global.convertCurrency(country, totalnet_payout)}}
              </template>
              <template #foot(profit)="data">
                {{ $global.convertCurrency(country, totalprofit)}}
              </template>
              <template #foot(sale_amount)="data">
                {{ $global.convertCurrency(country, totalordervalue)}}
              </template>
              <template #foot(aov)="data">
                {{ $global.convertCurrency(country, totalaov)}}
              </template>
              <template #foot(roi)="data">
                {{ $global.convertCurrency(country, totalroi)}}
              </template>
              <template #foot(returns)="data">
                {{ totalreturns }}
              </template>
              <template #foot(net_sale_amount)="data">
                {{ $global.convertCurrency(country, totalnetsaleamount)}}
              </template>
            </b-table>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import $ from 'jquery'

import LineChart from '@/components/charts/LineChart'
import BarChart from '@/components/charts/BarChart'
import moment from 'moment'
import Datepicker from 'vuejs-datepicker';
import Multiselect from 'vue-multiselect'

import Vue from 'vue'
import { log } from 'util'
import { stringify } from 'querystring'

export default {
  name: "AdlinkGeneralReportPivot",
  components: {
      LineChart,
      Datepicker,
      Multiselect,
      BarChart
  },
  data: function () {
    return {
      chartData: {},
      barChartTempData: {},
      items: [],
      updated: 0,
      rejected: [],
      sortType: 'date',
      showdates: false,
      campaign_name: null,
      loading: true,
      start_date: null,
      end_date: null,
      update_pivot: false,
      responseArrayConversions: [],
      brand_array: [],
      brands: [],
      brand_options: [],
      profiles_array: [],
      profiles: [],
      profiles_options: [],
      campaign_array: [],
      campaigns: [],
      statsByDate: [],
      statsByCampaign: [],
      statsByBrand: [],
      statsByCountry: [],
      statsByProfile: [],
      statsByProfileBars: [],
      campaign_options: [],
      totalclicks: 0,
      totaluniqueclicks: 0,
      totalconversions: 0,
      totalrevenue: 0,
      totalnet_payout: 0,
      totalprofit: 0,
      totalordervalue: 0,
      totalaov: 0,
      totalroi: 0,
      totalreturns: 0,
      totalnetsaleamount: 0,
      pivot_selected: "date",
      pivot_options: [
        { value: "date", text: "Date" },
        { value: "campaign", text: "Campaign" },
        { value: "brand", text: "Brand" },
        { value: "profile", text: "Profile" },
        { value: "country", text: "Country" }
      ],
      pivot_options_campaigns: [
        { value: "date", text: "Date" },
        { value: "profile", text: "Profile" },
        { value: "country", text: "Country" }
      ],
      buttons: [
        { label: "Clicks", value: 5 },
        { label: "Unique Clicks", value: 6 },
        { label: "Conversions", value: 7 },
        { label: "CR", value: 8 },
        { label: "Revenue", value: 9 },
        { label: "Commission", value: 10 },
        { label: "Profit", value: 11 },
        { label: "Order Value", value: 12 },
        { label: "AOV", value: 13 },
        { label: "COS%", value: 14 },
        { label: "ROI", value: 15 },
        { label: "Margin", value: 16 },
        { label: "Returns", value: 17 },
        { label: "Net Order Value", value: 18 }
      ],
      buttons_profile: [
        { label: "Clicks", value: 5 },
        { label: "Unique Clicks", value: 6 },
        { label: "Conversions", value: 7 },
        { label: "CR", value: 8 },
        { label: "Commission", value: 9 },
        { label: "Order Value", value: 10 },
        { label: "AOV", value: 11 },
        { label: "Returns", value: 12 },
        { label: "Net Order Value", value: 13 }
      ],
      fields: [
          {
            label: 'Campaign',
            key: 'campaign',
            sortable: true,
            class: 'hidden_header',
          },
          {
            label: 'Date',
            key: 'date',
            sortable: true,
            class: null,
          },
          {
            label: 'Brand',
            key: 'brand',
            sortable: true,
            class: 'hidden_header',
          },
          {
            label: 'Profile',
            key: 'profile',
            sortable: true,
            class: 'hidden_header',
          },
          {
            label: 'Country',
            key: 'country',
            sortable: true,
            class: 'hidden_header',
          },
          {
            label: 'Clicks',
            key: 'clicks',
            sortable: true,
            class: null,
          },
          {
            label: 'Unique Clicks',
            key: 'unique_clicks',
            sortable: true,
            class: null,
          },
          {
            label: 'Conversions',
            key: 'conversions',
            sortable: true,
            class: null,
          },
          {
            label: 'CR',
            key: 'cr',
            sortable: true,
            class: null,
          },
          {
            label: 'Revenue',
            key: 'revenue',
            sortable: true,
            class: null,
          },
          {
            label: 'Commission',
            key: 'net_payout',
            sortable: true,
            class: null,
          },
          {
            label: 'Profit',
            key: 'profit',
            sortable: true,
            class: null,
          },
          {
            label: 'Order value',
            key: 'sale_amount',
            sortable: true,
            class: null,
          },
          {
            label: 'AOV',
            key: 'aov',
            sortable: true,
            class: null,
          },
          {
            label: 'COS%',
            key: 'cos',
            sortable: true,
            class: null,
          },
          {
            label: 'ROI',
            key: 'roi',
            sortable: true,
            class: null,
          },
          {
            label: 'Margin',
            key: 'margin',
            sortable: true,
            class: null,
          },
          {
            label: 'Returns',
            key: 'returns',
            sortable: true,
            class: null,
          },
          {
            label: 'Net Order Value',
            key: 'net_sale_amount',
            sortable: true,
            class: null,
          },
      ],
      fields_profile: [
          {
            label: 'Campaign',
            key: 'campaign',
            sortable: true,
            class: 'hidden_header',
          },
          {
            label: 'Date',
            key: 'date',
            sortable: true,
            class: null,
          },
          {
            label: 'Brand',
            key: 'brand',
            sortable: true,
            class: 'hidden_header',
          },
          {
            label: 'Profile',
            key: 'profile',
            sortable: true,
            class: 'hidden_header',
          },
          {
            label: 'Country',
            key: 'country',
            sortable: true,
            class: 'hidden_header',
          },
          {
            label: 'Clicks',
            key: 'clicks',
            sortable: true,
            class: null,
          },
          {
            label: 'Unique Clicks',
            key: 'unique_clicks',
            sortable: true,
            class: null,
          },
          {
            label: 'Conversions',
            key: 'conversions',
            sortable: true,
            class: null,
          },
          {
            label: 'CR',
            key: 'cr',
            sortable: true,
            class: null,
          },
          {
            label: 'Commission',
            key: 'net_payout',
            sortable: true,
            class: null,
          },
          {
            label: 'Order value',
            key: 'sale_amount',
            sortable: true,
            class: null,
          },
          {
            label: 'AOV',
            key: 'aov',
            sortable: true,
            class: null,
          },
          {
            label: 'Returns',
            key: 'returns',
            sortable: true,
            class: null,
          },
          {
            label: 'Net Order Value',
            key: 'net_sale_amount',
            sortable: true,
            class: null,
          },
      ],
      breadcrumbs: [
        {
          text: 'Dashboard',
          to: '/dashboard'
        },
        {
          text: 'Reports',
          to: '/reports'
        },
        {
          text: 'Performance Breakdown',
          href: '#',
          active: true
        },
      ],
      breadcrumbs_campaign: [
        {
          text: 'Dashboard',
          to: '/dashboard'
        },
        {
          text: 'Campaigns',
          to: '/campaigns'
        },
        {
          text: 'Fixed Campaigns',
          to: '/list-adlinkcampaigns?type=active'
        },
        {
          text: 'Campaign report',
          href: '#',
          active: true
        },
      ],
    }
  },
  mounted() {
  },
  computed: {
    ...mapState(['country', 'type', 'profile_assets', 'brand_assets']),
  },
  created () {
    this.checkUser()
    this.setDate()
    this.getBrandList()
    this.getProfileList()
    this.getCampaignList()
    this.getCampaignStats()
    this.pickProfilesOnFirstLoad()
  },
  methods: {
    checkUser() {
      if(this.$store.state.type != 1) {
        if(this.$store.state.profile_id != null) {
          var profile = this.$store.state.profile_id;
          var index = _.findIndex(this.profile_assets, { 'profile_id': profile });
          this.current_tune_id = this.profile_assets[index].tune_id;
          this.profiles = [this.current_tune_id];
        }

        if(this.$store.state.brand_id != null) {
          var brand = this.$store.state.brand_id;  
          var index = _.findIndex(this.brand_assets, { 'brand_id': brand });
          this.current_tune_id = this.brand_assets[index].tune_id;
          this.brands = [this.current_tune_id];
          console.log(this.brands);
        }
      }
    },
    totalClicks() {
      this.totalclicks = 0;
      this.totaluniqueclicks = 0;
      this.totalconversions = 0;
      this.totalrevenue = 0;
      this.totalnet_payout = 0;
      this.totalprofit = 0;
      this.totalordervalue = 0;
      this.totalaov = 0;
      this.totalroi = 0;
      this.totalreturns = 0;
      this.totalnetsaleamount = 0;
      this.items.forEach((item) => {
        this.totalclicks = this.totalclicks + Number(item.clicks);
        this.totaluniqueclicks = this.totaluniqueclicks + Number(item.unique_clicks);
        this.totalconversions = this.totalconversions + Number(item.conversions);
        this.totalrevenue = this.totalrevenue + Number(item.revenue);
        this.totalnet_payout = this.totalnet_payout + Number(item.net_payout);
        this.totalprofit = this.totalprofit + Number(item.profit);
        this.totalordervalue = this.totalordervalue + Number(item.sale_amount);
        this.totalaov = this.totalaov + Number(item.aov);
        this.totalroi = this.totalroi + Number(item.roi);
        this.totalreturns = this.totalreturns + Number(item.returns);
        this.totalnetsaleamount = this.totalnetsaleamount + Number(item.net_sale_amount);
      })
    },
    sortButtons() {
      this.chartData['line'].datasets.forEach((item) => {
          var id = item.id
          var index = _.findIndex(this.chartData['line'].datasets, {id: id});
          this.chartData['line'].datasets[index].hidden = false
          $("#"+this.chartData['line'].datasets[index].id).addClass('active-sort')
          $("#"+this.chartData['line'].datasets[index].id).attr('style', 'background-color:'+this.chartData['line'].datasets[index].backgroundColor+'; border: 1px solid '+this.chartData['line'].datasets[index].backgroundColor)
        })
    },getCampaignStats() {
      this.statsByDate = [];
      this.statsByCampaign = [];
      this.statsByBrand = [];
      this.statsByCountry = [];
      this.statsByProfile = [];
      this.end_date = moment(this.end_date).format('YYYY-MM-DD')
      this.start_date = moment(this.start_date).format('YYYY-MM-DD');
      this.loading = true
      this.showdates = false
      let formData = new FormData();

      formData.append('NetworkToken', 'NET1KT6NezmINvx9n6mjzjx4rzXowH');
      formData.append('filters[Stat.date][conditional]', 'BETWEEN');
      formData.append('filters[Stat.date][values][]', this.start_date);
      formData.append('filters[Stat.date][values][]', this.end_date);
      formData.append('fields[]', 'Offer.name');
      formData.append('fields[]', 'Offer.ref_id');
      formData.append('limit', '0');

      //IF TYPE: DATE
      if( this.sortType == 'date' ) {

          formData.append('Target', 'Report');
          formData.append('Method', 'getConversions');
          formData.append('fields[]', 'Stat.date');
          formData.append('fields[]', 'Stat.status');
          formData.append('fields[]', 'Stat.net_sale_amount');
          formData.append('fields[]', 'Stat.sale_amount');
          formData.append('fields[]', 'Stat.net_payout');
          formData.append('fields[]', 'Stat.revenue');
          formData.append('totals', 1);
          formData.append('sort[Stat.date]=', 'ASC');

          formData.append('filters[Stat.status][conditional]', 'EQUAL_TO');
          formData.append('filters[Stat.status][values][]', 'rejected');
          formData.append('filters[Stat.status][values][]', 'pending');
          formData.append('filters[Stat.status][values][]', 'approved');

          if(this.brands.length > 0) {
            formData.append('filters[Advertiser.ref_id][conditional]', 'EQUAL_TO');
            this.brands.forEach((item) => {
              formData.append('filters[Advertiser.ref_id][values][]', item);
            })
          }

          if(this.profiles.length > 0) {
            formData.append('filters[Affiliate.ref_id][conditional]', 'EQUAL_TO');
            this.profiles.forEach((item) => {
              formData.append('filters[Affiliate.ref_id][values][]', item);
            })
          }

          if(this.campaigns.length > 0) {
            formData.append('filters[Offer.ref_id][conditional]', 'EQUAL_TO');
            this.campaigns.forEach((item) => {
              formData.append('filters[Offer.ref_id][values][]', item);
            })
          }

      } else {
        formData.append('Target', 'Report');
        formData.append('Method', 'getConversions');
        formData.append('fields[]', 'Stat.date');
        formData.append('fields[]', 'Stat.status');
        formData.append('fields[]', 'Stat.net_sale_amount');
        formData.append('fields[]', 'Stat.sale_amount');
        formData.append('fields[]', 'Stat.net_payout');
        formData.append('fields[]', 'Stat.revenue');
        formData.append('fields[]', 'Stat.country_code');
        formData.append('fields[]', 'Advertiser.ref_id');
        formData.append('fields[]', 'Advertiser.company');
        formData.append('fields[]', 'Affiliate.ref_id');
        formData.append('fields[]', 'Affiliate.company');
        formData.append('totals', 1);

        if(this.sortType == 'campaign') {
          formData.append('sort[Offer.ref_id]=', 'DESC');
        } else if(this.sortType == 'brand') {
          formData.append('sort[Advertiser.ref_id]=', 'ASC');
        } else if(this.sortType == 'profile') {
          formData.append('sort[Affiliate.ref_id]=', 'ASC');
        } else if(this.sortType == 'country') {
          formData.append('sort[Stat.country_code]=', 'ASC');
        } else {
          formData.append('sort[Stat.date]=', 'ASC');
        }


        formData.append('filters[Stat.status][conditional]', 'EQUAL_TO');
        formData.append('filters[Stat.status][values][]', 'rejected');
        formData.append('filters[Stat.status][values][]', 'pending');
        formData.append('filters[Stat.status][values][]', 'approved');

        if(this.campaigns.length > 0) {
          formData.append('filters[Offer.ref_id][conditional]', 'EQUAL_TO');
          this.campaigns.forEach((item) => {
            formData.append('filters[Offer.ref_id][values][]', item);
          })
        }

        if(this.profiles.length > 0) {
          formData.append('filters[Affiliate.ref_id][conditional]', 'EQUAL_TO');
          this.profiles.forEach((item) => {
            formData.append('filters[Affiliate.ref_id][values][]', item);
          })
        }

        if(this.brands.length > 0) {
          formData.append('filters[Advertiser.ref_id][conditional]', 'EQUAL_TO');
          this.brands.forEach((item) => {
            formData.append('filters[Advertiser.ref_id][values][]', item);
          })
        }

      }

      this.$http.tune.post('', formData)
      .then(response => this.getCampaignStatsSuccess(response))
      .catch(error => this.getCampaignStatsFailed(error))
    },
    getCampaignStatsSuccess(response) {

      if(response.data.response.status == 1) {

        this.statsByDate = []
        var responseArray = response.data.response.data.data
        var totals = response.data.response.data.totals.Stat
        var dates = []
        var conversions = []
        var revenue = []
        var net_payout = []
        var profit = []
        var sale_amount = []
        var net_sale_amount = []
        var aov = []
        var cos = []
        var roi = []
        var margin = []
        var returns = []
        this.items = []

        //Count the number of days between start_date and end_date
        var date1 = new Date(this.start_date);
        var date2 = new Date(this.end_date);

        var Difference_In_Time = date2.getTime() - date1.getTime();
        var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

        //Loop through number of day (+1 to count today) and add new date.
        var i;
        var date_to_add = this.start_date;
        for (i = 0; i < Difference_In_Days+1; i++) {
            //Fill array with correct dates. And fill info arrays with same amount of posts with value 0
            dates.push(date_to_add)
            conversions.push(0)
            revenue.push(0)
            net_payout.push(0)
            profit.push(0)
            sale_amount.push(0)
            net_sale_amount.push(0)
            margin.push(0)
            aov.push(0)
            cos.push(0)
            roi.push(0)
            returns.push(0)
            date_to_add = moment(date_to_add).add(1, 'days').format('YYYY-MM-DD');
        }

        //IF TYPE: DATE
        if( this.sortType == 'date' ) {

          responseArray.forEach((item) => {
            //Find index from date array corresponding to item date
            var dateIndex = dates.findIndex(dates => dates === item.Stat.date);

            //Add conversions
            var newConversionValue = conversions[dateIndex]+1;
            conversions[dateIndex] = newConversionValue

            //Add revenue
            var newRevenueValue = revenue[dateIndex]+parseFloat(item.Stat.revenue);
            revenue[dateIndex] = newRevenueValue

            //Add net_payout
            var newnet_payoutValue = net_payout[dateIndex]+parseFloat(item.Stat.net_payout);
            net_payout[dateIndex] = newnet_payoutValue

            //Add sale amount
            //Tune API returns empty string, set to 0 if empty
            if(item.Stat.sale_amount=="" || item.Stat.sale_amount==null) {
              var tuneSaleAmountNew = 0;
            } else {
              var tuneSaleAmountNew = item.Stat.sale_amount;
            }
            var newSaleAmountValue = sale_amount[dateIndex]+parseFloat(tuneSaleAmountNew);
            sale_amount[dateIndex] = newSaleAmountValue

            //Add Net Order Value
            //Tune API returns empty string, set to 0 if empty
            if(item.Stat.net_sale_amount=="" || item.Stat.net_sale_amount==null) {
              var tuneNetSaleAmountNew = 0;
            } else {
              var tuneNetSaleAmountNew = item.Stat.net_sale_amount;
            }
            var newNetSaleAmountValue = net_sale_amount[dateIndex]+parseFloat(tuneNetSaleAmountNew);
            net_sale_amount[dateIndex] = newNetSaleAmountValue

            //Add profit
            var profitValue = parseFloat(item.Stat.revenue)-parseFloat(item.Stat.net_payout);
            var newProfitValue = profit[dateIndex]+profitValue;
            profit[dateIndex] = newProfitValue

            //Add returns
            if(item.Stat.status=="rejected") {
              var newReturnsValue = returns[dateIndex]+1;
              returns[dateIndex] = newReturnsValue
            }

          });

          //Loop through dates and do new calculations based on results in previous loop
          dates.forEach((item, index) => {

            //Add AOV
            if(conversions[index]==0) {
              aov[index] = 0;
            } else {
              var aovValue = sale_amount[index]/conversions[index];
              var newAovValue = aov[index]+aovValue;
              aov[index] = newAovValue.toFixed(2)
            }

            if(this.type == 1) {
              //Add COS
              if(revenue[index]==0) {
                cos[index] = 0;
              } else {
                var cosValue = revenue[index]/sale_amount[index];
                var newCosValue = cos[index]+cosValue;
                cos[index] = newCosValue.toFixed(2)
              }

              //Add ROI
              if(revenue[index]==0) {
                roi[index] = 0;
              } else {
                var roiValue = sale_amount[index]/revenue[index];
                var newRoiValue = roi[index]+roiValue;
                roi[index] = newRoiValue.toFixed(2)
              }
            
              //Add margin
              var marginValueSEK = profit[index]/revenue[index];
              var marginValue = marginValueSEK*100;
              var newMarginValue = margin[index]+marginValue;
              margin[index] = newMarginValue.toFixed(2)
            }
            

            //V2.0
            this.statsByDate.push({
              date: item,
              conversions: conversions[index],
              revenue: revenue[index],
              net_payout: net_payout[index],
              profit: profit[index],
              sale_amount: sale_amount[index],
              net_sale_amount: net_sale_amount[index],
              aov: aov[index],
              cos: cos[index],
              roi: roi[index],
              margin: margin[index],
              returns: returns[index],
            })

          });

        } else if( this.sortType == 'campaign' ) {
          //IF TYPE: CAMPAIGN

          var old_ref_id = 0;

          responseArray.forEach((item) => {

            if(old_ref_id!=item.Offer.ref_id) {
              this.statsByCampaign[item.Offer.ref_id] = {
                campaign: item.Offer.name,
                ref_id: item.Offer.ref_id,
                clicks: 0,
                unique_clicks: 0,
                conversions: 0,
                revenue: 0,
                net_payout: 0,
                profit: 0,
                sale_amount: 0,
                aov: 0,
                cos: 0,
                roi: 0,
                margin: 0,
                returns: 0,
                cr: 0,
                net_sale_amount: 0
              }
              old_ref_id = item.Offer.ref_id
            }

            //Add conversions
            this.statsByCampaign[item.Offer.ref_id].conversions = this.statsByCampaign[item.Offer.ref_id].conversions+1

            //Add revenue
            var newRevenueValue = this.statsByCampaign[item.Offer.ref_id].revenue+parseFloat(item.Stat.revenue);
            this.statsByCampaign[item.Offer.ref_id].revenue = newRevenueValue

            //Add net_payout
            var newnet_payoutValue = this.statsByCampaign[item.Offer.ref_id].net_payout+parseFloat(item.Stat.net_payout);
            this.statsByCampaign[item.Offer.ref_id].net_payout = newnet_payoutValue

            //Add returns
            if(item.Stat.status == 'rejected') {
              this.statsByCampaign[item.Offer.ref_id].returns = this.statsByCampaign[item.Offer.ref_id].returns+1
            }

            //Add sale amount
            //Tune API returns empty string, set to 0 if empty
            if(item.Stat.sale_amount=="" || item.Stat.sale_amount==null) {
              var tuneSaleAmountNew = 0;
            } else {
              var tuneSaleAmountNew = item.Stat.sale_amount;
            }
            var newSaleAmountValue = this.statsByCampaign[item.Offer.ref_id].sale_amount+parseFloat(tuneSaleAmountNew);
            this.statsByCampaign[item.Offer.ref_id].sale_amount = newSaleAmountValue

            //Add Net Order Value
            //Tune API returns empty string, set to 0 if empty
            if(item.Stat.net_sale_amount=="" || item.Stat.net_sale_amount==null) {
              var tuneNetSaleAmountNew = 0;
            } else {
              var tuneNetSaleAmountNew = item.Stat.net_sale_amount;
            }
            var newNetSaleAmountValue = this.statsByCampaign[item.Offer.ref_id].net_sale_amount+parseFloat(tuneNetSaleAmountNew);
            this.statsByCampaign[item.Offer.ref_id].net_sale_amount = newNetSaleAmountValue

            //Add profit
            var profitValue = parseFloat(item.Stat.revenue)-parseFloat(item.Stat.net_payout);
            var newProfitValue = this.statsByCampaign[item.Offer.ref_id].profit+profitValue;
            this.statsByCampaign[item.Offer.ref_id].profit = newProfitValue

          })

          //Loop through campaigns and do new calculations based on results in previous loop
          this.statsByCampaign.forEach((item, index) => {

            //Add AOV
            if(this.statsByCampaign[index].conversions==0) {
              this.statsByCampaign[index].aov = 0;
            } else {
              var aovValue = this.statsByCampaign[index].sale_amount/this.statsByCampaign[index].conversions;
              this.statsByCampaign[index].aov = aovValue.toFixed(2)
            }

            //Add COS
            if(this.statsByCampaign[index].revenue==0) {
              this.statsByCampaign[index].cos = 0;
            } else {
              var cosValue = this.statsByCampaign[index].revenue/this.statsByCampaign[index].sale_amount;
              var newCosValue = this.statsByCampaign[index].cos+cosValue;
              this.statsByCampaign[index].cos = newCosValue.toFixed(2)
            }

            //Add ROI
            if(this.statsByCampaign[index].revenue==0) {
              this.statsByCampaign[index].roi = 0;
            } else {
              var roiValue = this.statsByCampaign[index].sale_amount/this.statsByCampaign[index].revenue;
              var newRoiValue = this.statsByCampaign[index].roi+roiValue;
              this.statsByCampaign[index].roi = newRoiValue.toFixed(2)
            }

            //Add margin
            var marginValueSEK = this.statsByCampaign[index].profit/this.statsByCampaign[index].revenue;
            var marginValue = marginValueSEK*100;
            var newMarginValue = this.statsByCampaign[index].margin+marginValue;
            this.statsByCampaign[index].margin = newMarginValue.toFixed(2)

          });

          this.items = []
          this.items = this.statsByCampaign
          this.$refs.pivotTable.refresh();

        } else if( this.sortType == 'brand' ) {
          //IF TYPE: BRAND

          var old_ref_id = 0;

          responseArray.forEach((item) => {

            if(item.Advertiser.ref_id=='') {
              var ref_from_tune = null
            } else {
              var ref_from_tune = item.Advertiser.ref_id
            }

            if(old_ref_id!=ref_from_tune) {
              this.statsByBrand[ref_from_tune] = {
                brand: item.Advertiser.company,
                ref_id: ref_from_tune,
                clicks: 0,
                unique_clicks: 0,
                conversions: 0,
                revenue: 0,
                net_payout: 0,
                profit: 0,
                sale_amount: 0,
                aov: 0,
                cos: 0,
                roi: 0,
                cr: 0,
                margin: 0,
                returns: 0,
                net_sale_amount: 0
              }
              old_ref_id = ref_from_tune
            }

            //Add conversions
            this.statsByBrand[ref_from_tune].conversions = this.statsByBrand[ref_from_tune].conversions+1
            console.log(this.statsByBrand[ref_from_tune].conversions)
            //Add revenue
            var newRevenueValue = this.statsByBrand[ref_from_tune].revenue+parseFloat(item.Stat.revenue);
            this.statsByBrand[ref_from_tune].revenue = newRevenueValue

            //Add net_payout
            var newnet_payoutValue = this.statsByBrand[ref_from_tune].net_payout+parseFloat(item.Stat.net_payout);
            this.statsByBrand[ref_from_tune].net_payout = newnet_payoutValue

            //Add returns
            if(item.Stat.status == 'rejected') {
              this.statsByBrand[ref_from_tune].returns = this.statsByBrand[ref_from_tune].returns+1
            }

            //Add sale amount
            //Tune API returns empty string, set to 0 if empty
            if(item.Stat.sale_amount=="" || item.Stat.sale_amount==null) {
              var tuneSaleAmountNew = 0;
            } else {
              var tuneSaleAmountNew = item.Stat.sale_amount;
            }
            var newSaleAmountValue = this.statsByBrand[ref_from_tune].sale_amount+parseFloat(tuneSaleAmountNew);
            this.statsByBrand[ref_from_tune].sale_amount = newSaleAmountValue

            //Add Net Order Value
            //Tune API returns empty string, set to 0 if empty
            if(item.Stat.net_sale_amount=="" || item.Stat.net_sale_amount==null) {
              var tuneNetSaleAmountNew = 0;
            } else {
              var tuneNetSaleAmountNew = item.Stat.net_sale_amount;
            }
            var newNetSaleAmountValue = this.statsByBrand[ref_from_tune].net_sale_amount+parseFloat(tuneNetSaleAmountNew);
            this.statsByBrand[ref_from_tune].net_sale_amount = newNetSaleAmountValue

            //Add profit
            var profitValue = parseFloat(item.Stat.revenue)-parseFloat(item.Stat.net_payout);
            var newProfitValue = this.statsByBrand[ref_from_tune].profit+profitValue;
            this.statsByBrand[ref_from_tune].profit = newProfitValue

          })

          //Loop through dates and do new calculations based on results in previous loop
          this.statsByBrand.forEach((item, index) => {

            //Add AOV
            if(this.statsByBrand[index].conversions==0) {
              this.statsByBrand[index].aov = 0;
            } else {
              var aovValue = this.statsByBrand[index].sale_amount/this.statsByBrand[index].conversions;
              this.statsByBrand[index].aov = aovValue.toFixed(2)
            }

            //Add COS
            if(this.statsByBrand[index].revenue==0) {
              this.statsByBrand[index].cos = 0;
            } else {
              var cosValue = this.statsByBrand[index].revenue/this.statsByBrand[index].sale_amount;
              var newCosValue = this.statsByBrand[index].cos+cosValue;
              this.statsByBrand[index].cos = newCosValue.toFixed(2)
            }

            //Add ROI
            if(this.statsByBrand[index].revenue==0) {
              this.statsByBrand[index].roi = 0;
            } else {
              var roiValue = this.statsByBrand[index].sale_amount/this.statsByBrand[index].revenue;
              var newRoiValue = this.statsByBrand[index].roi+roiValue;
              this.statsByBrand[index].roi = newRoiValue.toFixed(2)
            }

            //Add margin
            var marginValueSEK = this.statsByBrand[index].profit/this.statsByBrand[index].revenue;
            var marginValue = marginValueSEK*100;
            var newMarginValue = this.statsByBrand[index].margin+marginValue;
            this.statsByBrand[index].margin = newMarginValue.toFixed(2)

          });

          this.items = []
          this.items = this.statsByBrand
          this.$refs.pivotTable.refresh();

        } else if( this.sortType == 'profile' ) {
          //IF TYPE: PROFILE

          var old_ref_id = 0;
          responseArray.forEach((item) => {

            if(old_ref_id!=item.Affiliate.ref_id) {
              this.statsByProfile[item.Affiliate.ref_id] = {
                profile: item.Affiliate.company,
                ref_id: item.Affiliate.ref_id,
                clicks: 0,
                unique_clicks: 0,
                conversions: 0,
                revenue: 0,
                net_payout: 0,
                profit: 0,
                sale_amount: 0,
                aov: 0,
                cos: 0,
                roi: 0,
                cr: 0,
                margin: 0,
                returns: 0,
                net_sale_amount: 0
              }
              old_ref_id = item.Affiliate.ref_id
            }

            //Add conversions
            this.statsByProfile[item.Affiliate.ref_id].conversions = this.statsByProfile[item.Affiliate.ref_id].conversions+1

            //Add revenue
            var newRevenueValue = this.statsByProfile[item.Affiliate.ref_id].revenue+parseFloat(item.Stat.revenue);
            this.statsByProfile[item.Affiliate.ref_id].revenue = newRevenueValue

            //Add net_payout
            var newnet_payoutValue = this.statsByProfile[item.Affiliate.ref_id].net_payout+parseFloat(item.Stat.net_payout);
            this.statsByProfile[item.Affiliate.ref_id].net_payout = newnet_payoutValue

            //Add returns
            if(item.Stat.status == 'rejected') {
              this.statsByProfile[item.Affiliate.ref_id].returns = this.statsByProfile[item.Affiliate.ref_id].returns+1
            }

            //Add sale amount
            //Tune API returns empty string, set to 0 if empty
            if(item.Stat.sale_amount=="" || item.Stat.sale_amount==null) {
              var tuneSaleAmountNew = 0;
            } else {
              var tuneSaleAmountNew = item.Stat.sale_amount;
            }
            var newSaleAmountValue = this.statsByProfile[item.Affiliate.ref_id].sale_amount+parseFloat(tuneSaleAmountNew);
            this.statsByProfile[item.Affiliate.ref_id].sale_amount = newSaleAmountValue

            //Add Net Order Value
            //Tune API returns empty string, set to 0 if empty
            if(item.Stat.net_sale_amount=="" || item.Stat.net_sale_amount==null) {
              var tuneNetSaleAmountNew = 0;
            } else {
              var tuneNetSaleAmountNew = item.Stat.net_sale_amount;
            }
            var newNetSaleAmountValue = this.statsByProfile[item.Affiliate.ref_id].net_sale_amount+parseFloat(tuneNetSaleAmountNew);
            this.statsByProfile[item.Affiliate.ref_id].net_sale_amount = newNetSaleAmountValue

            //Add profit
            var profitValue = parseFloat(item.Stat.revenue)-parseFloat(item.Stat.net_payout);
            var newProfitValue = this.statsByProfile[item.Affiliate.ref_id].profit+profitValue;
            this.statsByProfile[item.Affiliate.ref_id].profit = newProfitValue

          })

          //Loop through dates and do new calculations based on results in previous loop
          this.statsByProfile.forEach((item, index) => {

            //Add AOV
            if(this.statsByProfile[index].conversions==0) {
              this.statsByProfile[index].aov = 0;
            } else {
              var aovValue = this.statsByProfile[index].sale_amount/this.statsByProfile[index].conversions;
              this.statsByProfile[index].aov = aovValue.toFixed(2)
            }

            //Add COS
            if(this.statsByProfile[index].revenue==0) {
              this.statsByProfile[index].cos = 0;
            } else {
              var cosValue = this.statsByProfile[index].revenue/this.statsByProfile[index].sale_amount;
              var newCosValue = this.statsByProfile[index].cos+cosValue;
              this.statsByProfile[index].cos = newCosValue.toFixed(2)
            }

            //Add ROI
            if(this.statsByProfile[index].revenue==0) {
              this.statsByProfile[index].roi = 0;
            } else {
              var roiValue = this.statsByProfile[index].sale_amount/this.statsByProfile[index].revenue;
              var newRoiValue = this.statsByProfile[index].roi+roiValue;
              this.statsByProfile[index].roi = newRoiValue.toFixed(2)
            }

            //Add margin
            var marginValueSEK = this.statsByProfile[index].profit/this.statsByProfile[index].revenue;
            var marginValue = marginValueSEK*100;
            var newMarginValue = this.statsByProfile[index].margin+marginValue;
            this.statsByProfile[index].margin = newMarginValue.toFixed(2)

          });

          this.items = []
          this.items = this.statsByProfile
          this.$refs.pivotTable.refresh();

        } else if( this.sortType == 'country' ) {
          //IF TYPE: COUNTRY

          var old_ref_id = '';


          responseArray.forEach((item) => {

            //Set custom key in array, the table requests numeric keys
            if(item.Stat.country_code=='SE') {
              var custom_key = 0;
            } else if(item.Stat.country_code=='INT') {
              var custom_key = 1;
            } else if(item.Stat.country_code=='US') {
              var custom_key = 2;
            } else {
              var custom_key = 3;
            }

            if(old_ref_id!=item.Stat.country_code) {
              this.statsByCountry[custom_key] = {
                country: item.Stat.country_code,
                ref_id: item.Stat.country_code,
                clicks: 0,
                unique_clicks: 0,
                conversions: 0,
                revenue: 0,
                net_payout: 0,
                profit: 0,
                sale_amount: 0,
                aov: 0,
                cos: 0,
                roi: 0,
                cr: 0,
                margin: 0,
                returns: 0,
                net_sale_amount: 0
              }
              old_ref_id = item.Stat.country_code
            }

            //Add conversions
            this.statsByCountry[custom_key].conversions = this.statsByCountry[custom_key].conversions+1

            //Add revenue
            var newRevenueValue = this.statsByCountry[custom_key].revenue+parseFloat(item.Stat.revenue);
            this.statsByCountry[custom_key].revenue = newRevenueValue

            //Add net_payout
            var newnet_payoutValue = this.statsByCountry[custom_key].net_payout+parseFloat(item.Stat.net_payout);
            this.statsByCountry[custom_key].net_payout = newnet_payoutValue

            //Add returns
            if(item.Stat.status == 'rejected') {
              this.statsByCountry[custom_key].returns = this.statsByCountry[custom_key].returns+1
            }

            //Add sale amount
            //Tune API returns empty string, set to 0 if empty
            if(item.Stat.sale_amount=="" || item.Stat.sale_amount==null) {
              var tuneSaleAmountNew = 0;
            } else {
              var tuneSaleAmountNew = item.Stat.sale_amount;
            }
            var newSaleAmountValue = this.statsByCountry[custom_key].sale_amount+parseFloat(tuneSaleAmountNew);
            this.statsByCountry[custom_key].sale_amount = newSaleAmountValue

            //Add Net Order Value
            //Tune API returns empty string, set to 0 if empty
            if(item.Stat.net_sale_amount=="" || item.Stat.net_sale_amount==null) {
              var tuneNetSaleAmountNew = 0;
            } else {
              var tuneNetSaleAmountNew = item.Stat.net_sale_amount;
            }
            var newNetSaleAmountValue = this.statsByCountry[custom_key].net_sale_amount+parseFloat(tuneNetSaleAmountNew);
            this.statsByCountry[custom_key].net_sale_amount = newNetSaleAmountValue

            //Add profit
            var profitValue = parseFloat(item.Stat.revenue)-parseFloat(item.Stat.net_payout);
            var newProfitValue = this.statsByCountry[custom_key].profit+profitValue;
            this.statsByCountry[custom_key].profit = newProfitValue

          })

          //Loop through dates and do new calculations based on results in previous loop
          this.statsByCountry.forEach((item, index) => {

            //Add AOV
            if(this.statsByCountry[index].conversions==0) {
              this.statsByCountry[index].aov = 0;
            } else {
              var aovValue = this.statsByCountry[index].sale_amount/this.statsByCountry[index].conversions;
              this.statsByCountry[index].aov = aovValue.toFixed(2)
            }

            //Add COS
            if(this.statsByCountry[index].revenue==0) {
              this.statsByCountry[index].cos = 0;
            } else {
              var cosValue = this.statsByCountry[index].revenue/this.statsByCountry[index].sale_amount;
              var newCosValue = this.statsByCountry[index].cos+cosValue;
              this.statsByCountry[index].cos = newCosValue.toFixed(2)
            }

            //Add ROI
            if(this.statsByCountry[index].revenue==0) {
              this.statsByCountry[index].roi = 0;
            } else {
              var roiValue = this.statsByCountry[index].sale_amount/this.statsByCountry[index].revenue;
              var newRoiValue = this.statsByCountry[index].roi+roiValue;
              this.statsByCountry[index].roi = newRoiValue.toFixed(2)
            }

            //Add margin
            var marginValueSEK = this.statsByCountry[index].profit/this.statsByCountry[index].revenue;
            var marginValue = marginValueSEK*100;
            var newMarginValue = this.statsByCountry[index].margin+marginValue;
            this.statsByCountry[index].margin = newMarginValue.toFixed(2)

          });

          this.items = []
          this.items = this.statsByCountry
          this.$refs.pivotTable.refresh();

        }

          this.getClickStats(dates)

      }
    },
    getCampaignStatsFailed(error) {
      console.log(this, 'Oh oh!', error, 'danger')
    },
    getClickStats(getDates) {

      this.end_date = moment(this.end_date).format('YYYY-MM-DD')
      this.start_date = moment(this.start_date).format('YYYY-MM-DD');
      this.loading = true
      this.showdates = false
      let formData = new FormData();

      formData.append('NetworkToken', 'NET1KT6NezmINvx9n6mjzjx4rzXowH');
      formData.append('filters[Stat.date][conditional]', 'BETWEEN');
      formData.append('filters[Stat.date][values][]', this.start_date);
      formData.append('filters[Stat.date][values][]', this.end_date);

      if(this.brands.length > 0) {
        formData.append('filters[Advertiser.ref_id][conditional]', 'EQUAL_TO');
        this.brands.forEach((item) => {
          formData.append('filters[Advertiser.ref_id][values][]', item);
        })
      }

      if(this.profiles.length > 0) {
        formData.append('filters[Affiliate.ref_id][conditional]', 'EQUAL_TO');
        this.profiles.forEach((item) => {
          formData.append('filters[Affiliate.ref_id][values][]', item);
        })
      }

      if(this.campaigns.length > 0) {
        formData.append('filters[Offer.ref_id][conditional]', 'EQUAL_TO');
        this.campaigns.forEach((item) => {
          formData.append('filters[Offer.ref_id][values][]', item);
        })
      }

      //IF TYPE: DATE
      if( this.sortType == 'date' ) {

        formData.append('Target', 'Report');
        formData.append('Method', 'getStats');
        formData.append('fields[]', 'Stat.date');
        formData.append('fields[]', 'Stat.clicks');
        formData.append('fields[]', 'Stat.unique_clicks');
        formData.append('totals', 1);
        formData.append('sort[Stat.date]=', 'ASC');


      } else if( this.sortType == 'campaign' ) {
        //IF TYPE: CAMPAIGN

        formData.append('Target', 'Report');
        formData.append('Method', 'getStats');
        formData.append('fields[]', 'Offer.name');
        formData.append('fields[]', 'Stat.clicks');
        formData.append('fields[]', 'Stat.unique_clicks');
        formData.append('totals', 1);
        formData.append('sort[Offer.ref_id]=', 'ASC');


      } else if( this.sortType == 'brand' ) {
        //IF TYPE: BRAND

        formData.append('Target', 'Report');
        formData.append('Method', 'getStats');
        formData.append('fields[]', 'Advertiser.company');
        formData.append('fields[]', 'Stat.clicks');
        formData.append('fields[]', 'Stat.unique_clicks');
        formData.append('totals', 1);
        formData.append('sort[Advertiser.ref_id]=', 'ASC');

      } else if( this.sortType == 'profile' ) {
        //IF TYPE: PROFILE

        formData.append('Target', 'Report');
        formData.append('Method', 'getStats');
        formData.append('fields[]', 'Affiliate.company');
        formData.append('fields[]', 'Stat.clicks');
        formData.append('fields[]', 'Stat.unique_clicks');
        formData.append('totals', 1);
        formData.append('sort[Affiliate.ref_id]=', 'ASC');

      } else if( this.sortType == 'country' ) {
        //IF TYPE: PROFILE

        formData.append('Target', 'Report');
        formData.append('Method', 'getStats');
        formData.append('fields[]', 'Stat.country_code');
        formData.append('fields[]', 'Stat.clicks');
        formData.append('fields[]', 'Stat.unique_clicks');
        formData.append('totals', 1);
        formData.append('sort[Stat.country_code]=', 'ASC');

      }


      this.$http.tune.post('', formData)
      .then(response => this.getClickStatsSuccess(response, getDates))
      .catch(error => this.getClickStatsFailed(error))
    },
    getClickStatsSuccess(response, getDates) {

      if(response.data.response.status == 1) {

        var responseArray = response.data.response.data.data
        var totals = response.data.response.data.totals.Stat
        var dates = getDates
        var clicks = []
        var unique_clicks = []

        //Count the number of days between start_date and end_date
        var date1 = new Date(this.start_date);
        var date2 = new Date(this.end_date);
        var Difference_In_Time = date2.getTime() - date1.getTime();
        var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

        //Loop through number of day (+1 to count today) and add new date.
        var i;
        var date_to_add = this.start_date;
        for (i = 0; i < Difference_In_Days+1; i++) {
            //Fill array with correct dates. And fill info arrays with same amount of posts with value 0
            clicks.push(0)
            unique_clicks.push(0)
        }

        //IF TYPE: DATE
        if( this.sortType == 'date' ) {

          this.fields[8].class = 'hidden_header'
          this.fields_profile[8].class = 'hidden_header'

          responseArray.forEach((item) => {
            //Find index from date array corresponding to item date
            var dateIndex = dates.findIndex(dates => dates === item.Stat.date);

            //Add clicks and unique clicks
            clicks[dateIndex] = item.Stat.clicks
            unique_clicks[dateIndex] = item.Stat.unique_clicks

          });

          dates.forEach((item, index) => {
            //V2.0
            this.statsByDate[index].clicks = clicks[index]
            this.statsByDate[index].unique_clicks = unique_clicks[index]

          });

          if(this.items.length==0) {
            this.items = this.statsByDate.reverse()
          }

          this.updated = this.updated+1
          this.loading=false

        } else if( this.sortType == 'campaign' ) {
          //IF TYPE: CAMPAIGN

          //Make CR column visible in pivot table
          this.fields[8].class = ''
          this.fields_profile[8].class = ''

          responseArray.forEach((item) => {

            var keyExists = _.findIndex(this.statsByCampaign, ['ref_id', item.Offer.ref_id]);
            if(keyExists!=-1) {
              this.statsByCampaign[item.Offer.ref_id].clicks = item.Stat.clicks
              this.statsByCampaign[item.Offer.ref_id].unique_clicks = item.Stat.unique_clicks

              //Add CR
              if(this.statsByCampaign[item.Offer.ref_id].conversions==0) {
                this.statsByCampaign[item.Offer.ref_id].cr = 0;
              } else {
                var crValue = this.statsByCampaign[item.Offer.ref_id].conversions/this.statsByCampaign[item.Offer.ref_id].unique_clicks*100;
                var newCRValue = this.statsByCampaign[item.Offer.ref_id].cr+crValue;
                this.statsByCampaign[item.Offer.ref_id].cr = newCRValue.toFixed(2)
              }

            } else {
              this.statsByCampaign[item.Offer.ref_id] = {
                campaign: item.Offer.name,
                ref_id: item.Offer.ref_id,
                clicks: item.Stat.clicks,
                unique_clicks: item.Stat.unique_clicks,
                conversions: 0,
                revenue: 0,
                net_payout: 0,
                profit: 0,
                sale_amount: 0,
                aov: 0,
                cos: 0,
                roi: 0,
                cr: 0,
                margin: 0,
                returns: 0,
                net_sale_amount: 0
              }
            }

          })
          this.$refs.pivotTable.refresh()
          this.loading=false
        } else if( this.sortType == 'brand' ) {
          //IF TYPE: BRAND

          //Make CR column visible in pivot table
          this.fields[8].class = ''
          this.fields_profile[8].class = ''

          responseArray.forEach((item) => {

            var keyExists = _.findIndex(this.statsByBrand, ['ref_id', item.Advertiser.ref_id]);
            if(keyExists!=-1) {
              this.statsByBrand[item.Advertiser.ref_id].clicks = item.Stat.clicks
              this.statsByBrand[item.Advertiser.ref_id].unique_clicks = item.Stat.unique_clicks

              //Add CR
              if(this.statsByBrand[item.Advertiser.ref_id].conversions==0) {
                this.statsByBrand[item.Advertiser.ref_id].cr = 0;
              } else {
                var crValue = (this.statsByBrand[item.Advertiser.ref_id].conversions/this.statsByBrand[item.Advertiser.ref_id].unique_clicks)*100;
                var newCRValue = this.statsByBrand[item.Advertiser.ref_id].cr+crValue;
                this.statsByBrand[item.Advertiser.ref_id].cr = newCRValue.toFixed(2)
              }

            } else {
              this.statsByBrand[item.Advertiser.ref_id] = {
                brand: item.Advertiser.company,
                ref_id: item.Advertiser.ref_id,
                clicks: item.Stat.clicks,
                unique_clicks: item.Stat.unique_clicks,
                conversions: 0,
                revenue: 0,
                net_payout: 0,
                profit: 0,
                sale_amount: 0,
                aov: 0,
                cos: 0,
                roi: 0,
                cr: 0,
                margin: 0,
                returns: 0,
                net_sale_amount: 0
              }
            }

          })
          this.$refs.pivotTable.refresh()
          this.loading=false
        } else if( this.sortType == 'profile' ) {
          //IF TYPE: PROFILE

          //Make CR column visible in pivot table
          this.fields[8].class = ''
          this.fields_profile[8].class = ''

          responseArray.forEach((item) => {

            var keyExists = _.findIndex(this.statsByProfile, ['ref_id', item.Affiliate.ref_id]);
            if(keyExists!=-1) {
              this.statsByProfile[item.Affiliate.ref_id].clicks = item.Stat.clicks
              this.statsByProfile[item.Affiliate.ref_id].unique_clicks = item.Stat.unique_clicks

              //Add CR
              if(this.statsByProfile[item.Affiliate.ref_id].conversions==0) {
                this.statsByProfile[item.Affiliate.ref_id].cr = 0;
              } else {
                var crValue = (this.statsByProfile[item.Affiliate.ref_id].conversions/this.statsByProfile[item.Affiliate.ref_id].unique_clicks)*100;
                var newCRValue = this.statsByProfile[item.Affiliate.ref_id].cr+crValue;
                this.statsByProfile[item.Affiliate.ref_id].cr = newCRValue.toFixed(2)
              }

            } else {
              this.statsByProfile[item.Affiliate.ref_id] = {
                brand: item.Affiliate.company,
                ref_id: item.Affiliate.ref_id,
                clicks: item.Stat.clicks,
                unique_clicks: item.Stat.unique_clicks,
                conversions: 0,
                revenue: 0,
                net_payout: 0,
                profit: 0,
                sale_amount: 0,
                aov: 0,
                cos: 0,
                roi: 0,
                cr: 0,
                margin: 0,
                returns: 0,
                net_sale_amount: 0
              }
            }

          })
          this.$refs.pivotTable.refresh()
          this.loading=false
        } else if( this.sortType == 'country' ) {
          //IF TYPE: COUNTRY

          //Make CR column visible in pivot table
          this.fields[8].class = ''
          this.fields_profile[8].class = ''

          responseArray.forEach((item) => {

            //Set custom key in array, the table requests numeric keys
            if(item.Stat.country_code=='SE') {
              var custom_key = 0;
            } else if(item.Stat.country_code=='INT') {
              var custom_key = 1;
            } else if(item.Stat.country_code=='US') {
              var custom_key = 2;
            } else {
              var custom_key = 3;
            }

            var keyExists = _.findIndex(this.statsByCountry, ['ref_id', item.Stat.country_code]);
            if(keyExists!=-1) {
              this.statsByCountry[custom_key].clicks = item.Stat.clicks
              this.statsByCountry[custom_key].unique_clicks = item.Stat.unique_clicks

              //Add CR
              if(this.statsByCountry[custom_key].conversions==0) {
                this.statsByCountry[custom_key].cr = 0;
              } else {
                var crValue = (this.statsByCountry[custom_key].conversions/this.statsByCountry[custom_key].unique_clicks)*100;
                var newCRValue = this.statsByCountry[custom_key].cr+crValue;
                this.statsByCountry[custom_key].cr = newCRValue.toFixed(2)
              }

            } else {
              this.statsByCountry[custom_key] = {
                country: item.Stat.country_code,
                ref_id: item.Stat.country_code,
                clicks: item.Stat.clicks,
                unique_clicks: item.Stat.unique_clicks,
                conversions: 0,
                revenue: 0,
                net_payout: 0,
                profit: 0,
                sale_amount: 0,
                aov: 0,
                cos: 0,
                roi: 0,
                cr: 0,
                margin: 0,
                returns: 0,
                net_sale_amount: 0
              }
            }

          })
          this.$refs.pivotTable.refresh()
          this.loading=false
        } else {

        }
        this.totalClicks();
    }
    },
    getClickStatsFailed(error) {
      console.log('Oh oh!')
    },
    updatePivot() {
      if(this.pivot_selected == "date") {

        //Hide/Show correct columns in pivot table
        var index = _.findIndex(this.fields, {key: "date"});
        this.fields[index].class = null
        var index2 = _.findIndex(this.fields, {key: "campaign"});
        this.fields[index2].class = 'hidden_header'
        var index3 = _.findIndex(this.fields, {key: "brand"});
        this.fields[index3].class = 'hidden_header'
        var index4 = _.findIndex(this.fields, {key: "profile"});
        this.fields[index4].class = 'hidden_header'
        var index5 = _.findIndex(this.fields, {key: "country"});
        this.fields[index5].class = 'hidden_header'

        var index = _.findIndex(this.fields_profile, {key: "date"});
        this.fields_profile[index].class = null
        var index2 = _.findIndex(this.fields_profile, {key: "campaign"});
        this.fields_profile[index2].class = 'hidden_header'
        var index3 = _.findIndex(this.fields_profile, {key: "brand"});
        this.fields_profile[index3].class = 'hidden_header'
        var index4 = _.findIndex(this.fields_profile, {key: "profile"});
        this.fields_profile[index4].class = 'hidden_header'
        var index5 = _.findIndex(this.fields_profile, {key: "country"});
        this.fields_profile[index5].class = 'hidden_header'

        this.sortType = 'date'
        this.getCampaignStats()

        this.items = this.statsByDate

      } else if (this.pivot_selected == "campaign") {

        //Hide/Show correct columns in pivot table
        var index = _.findIndex(this.fields, {key: "date"});
        this.fields[index].class = 'hidden_header'
        var index2 = _.findIndex(this.fields, {key: "campaign"});
        this.fields[index2].class = null
        var index3 = _.findIndex(this.fields, {key: "brand"});
        this.fields[index3].class = 'hidden_header'

        var index4 = _.findIndex(this.fields, {key: "profile"});
        this.fields[index4].class = 'hidden_header'
        var index5 = _.findIndex(this.fields, {key: "country"});
        this.fields[index5].class = 'hidden_header'

        //Hide/Show correct columns in pivot table
        var index = _.findIndex(this.fields_profile, {key: "date"});
        this.fields_profile[index].class = 'hidden_header'
        var index2 = _.findIndex(this.fields_profile, {key: "campaign"});
        this.fields_profile[index2].class = null
        var index3 = _.findIndex(this.fields_profile, {key: "brand"});
        this.fields_profile[index3].class = 'hidden_header'

        var index4 = _.findIndex(this.fields_profile, {key: "profile"});
        this.fields_profile[index4].class = 'hidden_header'
        var index5 = _.findIndex(this.fields_profile, {key: "country"});
        this.fields_profile[index5].class = 'hidden_header'

        this.sortType = 'campaign'
        this.getCampaignStats()
        this.items = this.statsByCampaign

      } else if (this.pivot_selected == "brand") {

        //Hide/Show correct columns in pivot table
        var index = _.findIndex(this.fields, {key: "date"});
        this.fields[index].class = 'hidden_header'
        var index2 = _.findIndex(this.fields, {key: "campaign"});
        this.fields[index2].class = 'hidden_header'
        var index3 = _.findIndex(this.fields, {key: "brand"});
        this.fields[index3].class = null
        var index4 = _.findIndex(this.fields, {key: "profile"});
        this.fields[index4].class = 'hidden_header'
        var index5 = _.findIndex(this.fields, {key: "country"});
        this.fields[index5].class = 'hidden_header'

        //Hide/Show correct columns in pivot table
        var index = _.findIndex(this.fields_profile, {key: "date"});
        this.fields_profile[index].class = 'hidden_header'
        var index2 = _.findIndex(this.fields_profile, {key: "campaign"});
        this.fields_profile[index2].class = 'hidden_header'
        var index3 = _.findIndex(this.fields_profile, {key: "brand"});
        this.fields_profile[index3].class = null
        var index4 = _.findIndex(this.fields_profile, {key: "profile"});
        this.fields_profile[index4].class = 'hidden_header'
        var index5 = _.findIndex(this.fields_profile, {key: "country"});
        this.fields_profile[index5].class = 'hidden_header'

        this.sortType = 'brand'
        this.getCampaignStats()
        this.items = this.statsByBrand

      } else if (this.pivot_selected == "profile") {

        //Hide/Show correct columns in pivot table
        var index = _.findIndex(this.fields, {key: "date"});
        this.fields[index].class = 'hidden_header'
        var index2 = _.findIndex(this.fields, {key: "campaign"});
        this.fields[index2].class = 'hidden_header'
        var index3 = _.findIndex(this.fields, {key: "brand"});
        this.fields[index3].class = 'hidden_header'
        var index4 = _.findIndex(this.fields, {key: "profile"});
        this.fields[index4].class = null
        var index5 = _.findIndex(this.fields, {key: "country"});
        this.fields[index5].class = 'hidden_header'

        //Hide/Show correct columns in pivot table
        var index = _.findIndex(this.fields_profile, {key: "date"});
        this.fields_profile[index].class = 'hidden_header'
        var index2 = _.findIndex(this.fields_profile, {key: "campaign"});
        this.fields_profile[index2].class = 'hidden_header'
        var index3 = _.findIndex(this.fields_profile, {key: "brand"});
        this.fields_profile[index3].class = 'hidden_header'
        var index4 = _.findIndex(this.fields_profile, {key: "profile"});
        this.fields_profile[index4].class = null
        var index5 = _.findIndex(this.fields_profile, {key: "country"});
        this.fields_profile[index5].class = 'hidden_header'

        this.sortType = 'profile'
        this.getCampaignStats()
        this.items = this.statsByProfile

      } else if (this.pivot_selected == "country") {

        //Hide/Show correct columns in pivot table
        var index = _.findIndex(this.fields, {key: "date"});
        this.fields[index].class = 'hidden_header'
        var index2 = _.findIndex(this.fields, {key: "campaign"});
        this.fields[index2].class = 'hidden_header'
        var index3 = _.findIndex(this.fields, {key: "brand"});
        this.fields[index3].class = 'hidden_header'
        var index4 = _.findIndex(this.fields, {key: "profile"});
        this.fields[index4].class = 'hidden_header'
        var index5 = _.findIndex(this.fields, {key: "country"});
        this.fields[index5].class = null

        //Hide/Show correct columns in pivot table
        var index = _.findIndex(this.fields_profile, {key: "date"});
        this.fields_profile[index].class = 'hidden_header'
        var index2 = _.findIndex(this.fields_profile, {key: "campaign"});
        this.fields_profile[index2].class = 'hidden_header'
        var index3 = _.findIndex(this.fields_profile, {key: "brand"});
        this.fields_profile[index3].class = 'hidden_header'
        var index4 = _.findIndex(this.fields_profile, {key: "profile"});
        this.fields_profile[index4].class = 'hidden_header'
        var index5 = _.findIndex(this.fields_profile, {key: "country"});
        this.fields_profile[index5].class = null

        this.sortType = 'country'
        this.getCampaignStats()
        this.items = this.statsByCountry

      }

    },
    updateGraph() {
      this.filterChart('all')

      $("#pivot").val('date');
      this.pivot_selected = 'date'
      this.sortType = 'date'

      this.profiles = []
      this.brands = []
      this.campaigns = []

      if(this.brand_array.length > 0) {
        this.brand_array.forEach((item) => {
          this.brands.push(item.value.tune_id)
        })
      } else {
        this.brands = []
        this.checkUser();
      }

      if(this.profiles_array.length > 0) {
        this.profiles_array.forEach((item) => {
          this.profiles.push(item.value.tune_id)
        })
      } else {
        this.profiles = []
        this.checkUser();
      }

      if(this.campaign_array.length > 0) {
        this.campaign_array.forEach((item) => {
          this.campaigns.push(item.value.tune_id)
        })
      } else {
        this.campaigns = []
        this.checkUser();
      }

      this.getCampaignStats()
      this.updatePivot()
      this.pickProfilesOnFirstLoad()
      this.updated=this.updated+1

    },
    getProfileList() {
      this.$http.bamse.get('assets/profile/listall')
        .then(response => this.getProfileListSuccess(response))
        //.catch(() => this.getProfileListFailed())
    },
    getProfileListSuccess(response) {

      if(response.data.success) {

        response.data.success.forEach((result, key) => {
          if(result.tune_id !== 0) {
            this.profiles_options.push({value: {tune_id: result.tune_id}, text: result.name})
          }
        });

        this.profiles_options = _.orderBy(this.profiles_options, ['text'],['asc']);

      }
    },
    getProfileListFailed() {
      //this.error = 'no fetch sorry'
    },
    getBrandList() {
      this.$http.bamse.get('assets/brand/listall')
        .then(response => this.getBrandListSuccess(response))
        .catch(() => this.getBrandListFailed())
    },
    getBrandListSuccess(response) {

      if(response.data.success) {

        response.data.success.forEach((result, key) => {
          if(result.tune_id !== 0) {
            this.brand_options.push({value: {tune_id: result.tune_id, brand_id: result.id}, text: result.company_name})
          }
        });

        this.brand_options = _.orderBy(this.brand_options, ['text'],['asc']);

      }
    },
    getCampaignList() {
      // byt till ny endpoint campaigns/adlink/listall när den är skapad
      this.$http.bamse.get('campaigns/adlink/listall')
        .then(response => this.getCampaignListSuccess(response))
        .catch(() => this.getCampaignListFailed())
    },
    getCampaignListSuccess(response) {

      if(response.data.success) {

        response.data.success.forEach((result, key) => {
          if(result.tune_id !== 0) {
            this.campaign_options.push({value: {tune_id: result.campaign_tune_id, brand_id: result.id}, text: result.campaign_name+' ('+result.brand_name+')'})
          }
        });

        this.campaign_options = _.orderBy(this.campaign_options, ['text'],['asc']);

      }
    },
    getCampaignListFailed() {
      //this.error = 'no fetch sorry'
    },
    pickFilter(id){

      this.chartData['line'].datasets.forEach((item) => {
          var id = item.id
          var index = _.findIndex(this.chartData['line'].datasets, {id: id});
          this.chartData['line'].datasets[index].hidden = false
          $("#"+this.chartData['line'].datasets[index].id).addClass('active-sort')
          $("#"+this.chartData['line'].datasets[index].id).attr('style', 'background-color:'+this.chartData['line'].datasets[index].backgroundColor+'; border: 1px solid '+this.chartData['line'].datasets[index].backgroundColor)

          if(this.statsByProfileBars.length>0) {
            this.chartData['bar'].datasets[index].hidden = false
            $("#"+this.chartData['bar'].datasets[index].id).addClass('active-sort')
            $("#"+this.chartData['bar'].datasets[index].id).attr('style', 'background-color:'+this.chartData['bar'].datasets[index].backgroundColor+'; border: 1px solid '+this.chartData['bar'].datasets[index].backgroundColor)
          }

          if(id!=0 && id!=1 && id!=2 && id!=3 && id!=4 ) {
            this.fields[5].class = ''
            this.fields[6].class = ''
            this.fields[7].class = ''
            this.fields[8].class = ''
            this.fields[9].class = ''
            this.fields[10].class = ''
            this.fields[11].class = ''
            this.fields[12].class = ''
            this.fields[13].class = ''
            this.fields[14].class = ''
            this.fields[15].class = ''
            this.fields[16].class = ''
            this.fields[17].class = ''
            this.fields[18].class = ''

            this.fields_profile[5].class = ''
            this.fields_profile[6].class = ''
            this.fields_profile[7].class = ''
            this.fields_profile[8].class = ''
            this.fields_profile[9].class = ''
            this.fields_profile[10].class = ''
            this.fields_profile[11].class = ''
            this.fields_profile[12].class = ''
            this.fields_profile[13].class = ''
          }
      })

      if(id == 1){


        var filterList = [4,5,6,7,8,9,10,11,12,13]

        filterList.forEach((id) => {
          var index = _.findIndex(this.chartData['line'].datasets, {id: id});
          this.chartData['line'].datasets[index].hidden = !this.chartData['line'].datasets[index].hidden
          $("#"+this.chartData['line'].datasets[index].id).removeClass('active-sort')
          $("#"+this.chartData['line'].datasets[index].id).attr('style', 'background-color: #fff; border: 1px solid #000;')

          if(this.statsByProfileBars.length>0) {
            this.chartData['bar'].datasets[index].hidden = !this.chartData['bar'].datasets[index].hidden
            $("#"+this.chartData['bar'].datasets[index].id).removeClass('active-sort')
            $("#"+this.chartData['bar'].datasets[index].id).attr('style', 'background-color: #fff; border: 1px solid #000;')
          }

        });

        //Remove columns from pivot table
        this.fields[8].class = 'hidden_header'
        this.fields[9].class = 'hidden_header'
        this.fields[10].class = 'hidden_header'
        this.fields[11].class = 'hidden_header'
        this.fields[12].class = 'hidden_header'
        this.fields[13].class = 'hidden_header'
        this.fields[14].class = 'hidden_header'
        this.fields[15].class = 'hidden_header'
        this.fields[16].class = 'hidden_header'
        this.fields[17].class = 'hidden_header'
        this.fields[18].class = 'hidden_header'

        this.fields_profile[8].class = 'hidden_header'
        this.fields_profile[9].class = 'hidden_header'
        this.fields_profile[10].class = 'hidden_header'
        this.fields_profile[11].class = 'hidden_header'
        this.fields_profile[12].class = 'hidden_header'
        this.fields_profile[13].class = 'hidden_header'

        this.updated = this.updated+1

      } else if(id == 2){

        var filterList = [1,2,3,4,5,6,7,11,12,13]

        filterList.forEach((id) => {
          var index = _.findIndex(this.chartData['line'].datasets, {id: id});
          this.chartData['line'].datasets[index].hidden = !this.chartData['line'].datasets[index].hidden
          $("#"+this.chartData['line'].datasets[index].id).removeClass('active-sort')
          $("#"+this.chartData['line'].datasets[index].id).attr('style', 'background-color: #fff; border: 1px solid #000;')

          if(this.statsByProfileBars.length>0) {
            this.chartData['bar'].datasets[index].hidden = !this.chartData['bar'].datasets[index].hidden
            $("#"+this.chartData['bar'].datasets[index].id).removeClass('active-sort')
            $("#"+this.chartData['bar'].datasets[index].id).attr('style', 'background-color: #fff; border: 1px solid #000;')
          }
        });

        //Remove columns from pivot table
        this.fields[5].class = 'hidden_header'
        this.fields[6].class = 'hidden_header'
        this.fields[7].class = 'hidden_header'
        this.fields[8].class = 'hidden_header'
        this.fields[9].class = 'hidden_header'
        this.fields[10].class = 'hidden_header'
        this.fields[11].class = 'hidden_header'
        this.fields[12].class = 'hidden_header'
        this.fields[16].class = 'hidden_header'
        this.fields[17].class = 'hidden_header'
        this.fields[18].class = 'hidden_header'

        this.fields_profile[5].class = 'hidden_header'
        this.fields_profile[6].class = 'hidden_header'
        this.fields_profile[7].class = 'hidden_header'
        this.fields_profile[8].class = 'hidden_header'
        this.fields_profile[9].class = 'hidden_header'
        this.fields_profile[10].class = 'hidden_header'
        this.fields_profile[11].class = 'hidden_header'
        this.fields_profile[12].class = 'hidden_header'

        this.updated = this.updated+1

      } else if(id == 3){

        var filterList = [1,2,3,8,9,10,12]

        filterList.forEach((id) => {
          var index = _.findIndex(this.chartData['line'].datasets, {id: id});
          this.chartData['line'].datasets[index].hidden = !this.chartData['line'].datasets[index].hidden
          $("#"+this.chartData['line'].datasets[index].id).removeClass('active-sort')
          $("#"+this.chartData['line'].datasets[index].id).attr('style', 'background-color: #fff; border: 1px solid #000;')

          if(this.statsByProfileBars.length>0) {
            this.chartData['bar'].datasets[index].hidden = !this.chartData['bar'].datasets[index].hidden
            $("#"+this.chartData['bar'].datasets[index].id).removeClass('active-sort')
            $("#"+this.chartData['bar'].datasets[index].id).attr('style', 'background-color: #fff; border: 1px solid #000;')
          }

            this.fields[5].class = 'hidden_header'
            this.fields[6].class = 'hidden_header'
            this.fields[7].class = 'hidden_header'
            this.fields[8].class = 'hidden_header'
            this.fields[13].class = 'hidden_header'
            this.fields[14].class = 'hidden_header'
            this.fields[15].class = 'hidden_header'
            this.fields[17].class = 'hidden_header'

            this.fields_profile[5].class = 'hidden_header'
            this.fields_profile[6].class = 'hidden_header'
            this.fields_profile[7].class = 'hidden_header'
            this.fields_profile[8].class = 'hidden_header'
            this.fields_profile[13].class = 'hidden_header'
        });

        this.updated = this.updated+1

      }
    },
    pickDates(id){

      $("#pivot").val('date');
      this.pivot_selected = 'date'
      this.sortType = 'date'

      if(id == 1){
        this.end_date = moment().format('YYYY-MM-DD')
        this.start_date = moment().subtract(7, 'days').format('YYYY-MM-DD');
        this.updateGraph()
      } else if(id == 2){
        this.end_date = moment().format('YYYY-MM-DD')
        this.start_date = moment().subtract(30, 'days').format('YYYY-MM-DD');
        this.updateGraph()
      } else if(id == 3){
        this.end_date = moment().format('YYYY-MM-DD')
        this.start_date = moment().subtract(90, 'days').format('YYYY-MM-DD');
        this.updateGraph()
      }
      this.updatePivot()
      this.pickProfilesOnFirstLoad()
    },
    setDate(){
      this.end_date = moment().format('YYYY-MM-DD')
      this.start_date = moment().subtract(30, 'days').format('YYYY-MM-DD');

      if(this.$route.query.campaign) {
        var campaignID = parseInt(this.$route.query.campaign)
        this.campaigns.push(campaignID)
      }

    },
    pickProfilesOnFirstLoad() {
      var self = this
      setTimeout(function() {
        $("#pivot").val('profile');
        self.pivot_selected = 'profile'
        self.updatePivot();
      }, 2000);
    },
    filterChart(label) {

      if(label == "all"){

          this.fields[5].class = ''
          this.fields[6].class = ''
          this.fields[7].class = ''
          this.fields[8].class = ''
          this.fields[9].class = ''
          this.fields[10].class = ''
          this.fields[11].class = ''
          this.fields[12].class = ''
          this.fields[13].class = ''
          this.fields[14].class = ''
          this.fields[15].class = ''
          this.fields[16].class = ''
          this.fields[17].class = ''
          this.fields[18].class = ''
          
          this.fields_profile[5].class = ''
          this.fields_profile[6].class = ''
          this.fields_profile[7].class = ''
          this.fields_profile[8].class = ''
          this.fields_profile[9].class = ''
          this.fields_profile[10].class = ''
          this.fields_profile[11].class = ''
          this.fields_profile[12].class = ''
          this.fields_profile[13].class = ''

          this.buttons.forEach(function(item) {
            $("#"+item.value).attr('style', 'background-color: #000; border: 1px solid #000; color: #fff;')
            $("#"+item.value).addClass('active-sort')
          });

          this.buttons_profile.forEach(function(item) {
            $("#"+item.value).attr('style', 'background-color: #000; border: 1px solid #000; color: #fff;')
            $("#"+item.value).addClass('active-sort')
          });


        } else if (label == "hideall"){

          this.fields[5].class = 'hidden_header'
          this.fields[6].class = 'hidden_header'
          this.fields[7].class = 'hidden_header'
          this.fields[8].class = 'hidden_header'
          this.fields[9].class = 'hidden_header'
          this.fields[10].class = 'hidden_header'
          this.fields[11].class = 'hidden_header'
          this.fields[12].class = 'hidden_header'
          this.fields[13].class = 'hidden_header'
          this.fields[14].class = 'hidden_header'
          this.fields[15].class = 'hidden_header'
          this.fields[16].class = 'hidden_header'
          this.fields[17].class = 'hidden_header'
          this.fields[18].class = 'hidden_header'

          this.fields_profile[5].class = 'hidden_header'
          this.fields_profile[6].class = 'hidden_header'
          this.fields_profile[7].class = 'hidden_header'
          this.fields_profile[8].class = 'hidden_header'
          this.fields_profile[9].class = 'hidden_header'
          this.fields_profile[10].class = 'hidden_header'
          this.fields_profile[11].class = 'hidden_header'
          this.fields_profile[12].class = 'hidden_header'
          this.fields_profile[13].class = 'hidden_header'

          this.buttons.forEach(function(item) {
            $("#"+item.value).attr('style', 'background-color: #fff; border: 1px solid #000; color: #000;')
            $("#"+item.value).removeClass('active-sort')
          });

          this.buttons_profile.forEach(function(item) {
            $("#"+item.value).attr('style', 'background-color: #fff; border: 1px solid #000; color: #000;')
            $("#"+item.value).removeClass('active-sort')
          });

      } else {
        
        if($("#"+label).hasClass('active-sort')) {
          console.log("has active")
          this.fields[label].class = 'hidden_header'
          this.fields_profile[label].class = 'hidden_header'
          $("#"+label).attr('style', 'background-color: #fff; border: 1px solid #000; color: #000;')
          $("#"+label).removeClass('active-sort')
        } else {
          console.log("has not active")
          this.fields[label].class = ''
          this.fields_profile[label].class = ''
          $("#"+label).attr('style', 'background-color: #000; border: 1px solid #fff; color: #fff;')
          $("#"+label).addClass('active-sort')
        }

      }
    },
  },
};
</script>
