<template>
  <b-card>
    <div slot="header">
        Brands
        <b-button 
          v-if="showButton" 
          @click="addBrandToUser()" 
          size="sm" 
          variant="success"
          class="card-header-btns"
        >
          Update selected brands
        </b-button>
    </div>
    <div class="all-users">
      <b-row>
        <b-col class="table_filter" cols="12">
          <b-input-group>
            <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col cols="12" v-if="view == 'listbrands'">
          <b-table 
            striped
            hover 
            responsive
            :items="level === 1 ? items : brand_assets" 
            :fields="fields"
            :filter="filter"
            @filtered="onFiltered"
          >
            <template v-slot:cell(type)="row">
              Brand
            </template>
            <template v-slot:cell(company_name)="row">
              <b-img 
                class="table-img"
                fluid 
                :src="getBrandImg(level === 1 ? row.item.id : row.item.brand_id)" 
                @error="replaceByDefault"
              ></b-img>
              {{ row.item.company_name }}
            </template>
            <template v-slot:cell(tune_id)="row">
             <span v-if="row.item.tune_id == 0">Disconnected</span>
             <span v-if="row.item.tune_id != 0">Connected</span>
            </template>
            <template v-slot:cell(id)="row">
                <router-link :to="{ name: 'manageasset', query: { id: level === 1 ? row.item.id : row.item.brand_id, type: 2 }}">
                  <b-button size="sm" variant="outline-primary">Edit Asset</b-button>
                </router-link>
            </template>
            </b-table>
          </b-col>

          <b-col class="manageusertable" cols="12" v-else-if="view == 'manageuser'">
            <b-table 
              striped
              selectable
              select-mode="range"
              selectedVariant="success"
              :items="items" 
              :fields="fields_manage_user"
              thead-class="hidden_header"
              :filter="filter"
              @filtered="onFiltered"
              @row-selected="rowSelected"
              ref="allbrands"
            >
              <template v-slot:cell(activated)="row">
                <font-awesome-icon icon="badge-check" v-if="checkIfOwnedByUser(row.item.id)" />
              </template>
            </b-table>
          </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>

import Alerts from "@/components/general/Alerts.vue";
import { mapState } from 'vuex'

export default {
  name: "listbrands",
  props:['view'],
  components: {
    Alerts
  },
  created () {
    this.listAssets()
  },
  data() {
    return {
      id: this.$route.query.id,
      error: false,
      success: false,
      users: null,
      filter: null,
      showButton: false,
      activated_brands: [],
      selected_brands: [],
      // Note `isActive` is left out and will not appear in the rendered table
      fields: [
        {
          key: 'company_name',
          sortable: true
        },
        {
          key: 'tune_id',
          sortable: true,
          label: 'Adlinks'
        },
        {
          key: 'id',
          label: 'Edit'
        }
      ],
      fields_manage_user: [
        {
          key: 'company_name',
          sortable: false,
          label: false
        },
        {
          key: 'activated',
          label: false
        }
      ],
      items: [],
    }
  },
  computed: {
    ...mapState(['profile_assets', 'ext_brand_assets', 'name', 'brand_assets', 'level', 'type', 'current_id']),
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key }
        })
    }
  },
  methods: {
    getBrandImg(brand_id){
      return this.$global.spacesURL()+'brands/brand_' + brand_id + '/profile_picture/brand_' + brand_id + '.png'
    },
    replaceByDefault(e){
        var url = this.$global.spacesURL()+"placeholders/profile-placeholder.png"
        e.target.src = url
    },
    listAssets() {
      this.$http.bamse.get('assets/brand/listall')
        .then(response => this.listAssetsInfoSuccess(response))
        .catch(() => this.listAssetsInfoFailed())
    },
    listAssetsInfoSuccess(response) {
      if(response.data.success) {
        this.items = response.data.success

        var i;
        for(i = 1; i <= response.data.success.length; i++){
          var check = this.ext_brand_assets.find(x => x.brand_id === response.data.success[i-1].id);
          if(check != undefined){
            this.activated_brands.push(response.data.success[i-1].id);
          } else {
          }
        }
      }
    },
    listAssetsInfoFailed() {
      this.error = 'no fetch sorry'
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    checkIfOwnedByUser(id) {
      var check = this.activated_brands.includes(id);
      if(check == true){
        return true
      } else {
        return false
      }
    },
    rowSelected(items) {
      this.selected_brands = items
      if (this.selected_brands && this.selected_brands.length) {
        this.showButton = true
      } else {
        this.showButton = false
      }
    },
    addBrandToUser() {
      if(this.selected_brands.length > 0) {
        var i;
        var id_array = []
        for(i = 1; i <= this.selected_brands.length; i++){
          id_array.push({id: this.selected_brands[i-1].id});
        }
        this.$http.bamse.post('assets/brand/connect', { 
          user_id: this.id, 
          brand_id: id_array
        })
          .then(response => this.addBrandToUserSuccess(response))
          .catch(() => this.addBrandToUserFailed())
      }
    },
    addBrandToUserSuccess(response) {
      if(response.data.success) {
        this.success = true
        
        var i;
        for(i = 1; i <= this.selected_brands.length; i++){
          var item = this.selected_brands[i-1].id;
          var check = this.activated_brands.includes(item);
          if(check == true){
            var index = this.activated_brands.indexOf(item);
            if (index !== -1) this.activated_brands.splice(index, 1);

            if (this.id == this.current_id) {
              if (index !== -1) this.brand_assets.splice(index, 1);
            } else {
              if (index !== -1) this.ext_brand_assets.splice(index, 1);
            }

          } else {
            this.activated_brands.push(this.selected_brands[i-1].id);

            if (this.id == this.current_id) {
              this.brand_assets.push(
                {
                  brand_id: this.selected_profiles[i-1].id,
                  company_name: this.selected_profiles[i-1].name
                }
              )
            } else {
              this.ext_brand_assets.push(
              {
                brand_id: this.selected_brands[i-1].id,
                company_name: this.selected_brands[i-1].company_name
              }
            )
            }

            
          }
        }

        this.$refs.allbrands.clearSelected()
        this.$global.makeToast(this, 'Success', 'Brands updated', 'success')

      }
    },
    addBrandToUserFailed() {
      this.error = true
    },
  },
};
</script>