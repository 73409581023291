<template>
  <div class="userbank">
    <b-row>
      <b-col cols="12" class="header">
        <h1>Money</h1>
        <b-breadcrumb :items="breadcrumbs"></b-breadcrumb>
      </b-col>
    </b-row>
	<b-row>
      <b-col md="4">
        <b-card title="Available" >
          <b-list-group flush>
            <b-list-group-item>
              <h2>{{ $global.convertCurrency(country, balance)}}</h2>
            </b-list-group-item>
          </b-list-group>
        </b-card>
      </b-col>
      <b-col md="4">
        <b-card title="Pending" >
          <b-list-group flush>
            <b-list-group-item>
              <h2>{{ $global.convertCurrency(country, pending_in)}}</h2>
            </b-list-group-item>
          </b-list-group>
        </b-card>
      </b-col>
      <b-col md="4">
        <b-card title="Ready to invoice" >
          <b-list-group flush>
            <b-list-group-item>
              <h2>{{ $global.convertCurrency(country, pending_out)}}</h2>
            </b-list-group-item>
          </b-list-group>
        </b-card>
      </b-col>
      <b-col cols="12">
        <b-card title="Invoice history">
          <b-table 
          striped
          responsive
          :items="items" 
          :fields="fields"
          sortBy='id'
          :sort-desc='sortDesc'
          perPage=5
          >
          <template v-slot:cell(amount)="row">
            {{ $global.convertCurrency(country, row.item.amount)}}
          </template>
          <template v-slot:cell(id)="row">
            <b-button size="sm" target="_blank"
            :href="getInvoicePdf(row.item.url,
            row.item.profile_id)" variant="outline-primary">Invoice</b-button>
          </template>
          </b-table>
          <router-link :to="{ name: 'withdrawalhistory'}">
            <b-button block variant="outline-primary">View All</b-button>
          </router-link>
        </b-card>
      </b-col>
      </b-row>
  </div>
</template>

<script>

import { mapState } from 'vuex'
import $ from 'jquery'

export default {
  name: "dashboard",
  components: {
  },
  computed: {
    ...mapState(['profile_assets', 'name', 'brand_assets', 'level', 'type',
    'current_id', 'country']),
  },
  created () {
    this.getBank()
    this.listWithdrawals()
  },
  data: function () {
    return {
      pending_in: '',
      pending_out: '',
      sortDesc: true,
      balance: '',
      profile_withdraw_id: '',
      withdraw_amount: null,
      fields: [
        {
          key: 'withdrawal_requested',
          label: 'Date',
          sortable: true,
        },
        {
          key: 'amount',
          sortable: true
        },
        {
          key: 'id',
          label: 'Manage'
        }
      ],
      items: [],
      breadcrumbs: [
        {
          text: 'Dashboard',
          to: '/dashboard'
        },
        {
          text: 'Money',
          active: true
        }
      ],
    }
  },
  methods: {
    getInvoicePdf(url, id){
      return this.$global.spacesURL()+'profiles/profile_'+id+'/invoices/'+url
    },
    withdraw(){
      if(this.withdraw_amount >= 500 && this.withdraw_amount <= this.balance) {
        this.$bvModal.show('modal_withdraw')
      } else {
        if(this.withdraw_amount < 500) {
          this.$global.makeToast(this, 'Oh oh!', 'Minimum withdrawal limit is 500SEK :(', 'danger')
        } 

        if(this.withdraw_amount > this.balance) {
          this.$global.makeToast(this, 'Oh oh!', 'You have insufficient funds for this withdrawal :(', 'danger')
        } 
      }
    },
    getBank() {

      var profile = this.$store.state.profile_id;  

      var index = _.findIndex(this.profile_assets, { 'profile_id': profile });
      console.log(index);

      this.pending_out = this.profile_assets[index].withdrawals_pending
      this.balance = this.profile_assets[index].balance
      this.pending_in = this.profile_assets[index].adlinks_pending
      this.profile_withdraw_id = this.profile_assets[index].profile_id
    },
    listWithdrawals() {
        this.$http.bamse.get('user/withdrawals')
          .then(response => this.listWithdrawalsSuccess(response))
          .catch(() => this.listWithdrawalsFailed())
    },
    listWithdrawalsSuccess(response) {
    if(response.data.success) {
      console.log(response.data.success)

      
        var profile = this.$store.state.profile_id;  

      var index = _.findIndex(this.profile_assets, { 'profile_id': profile });

      this.items = response.data.success[index]
      
    }
    },
    listWithdrawalsFailed() {
      this.error = 'no fetch sorry'
    },
  }
};
</script>
