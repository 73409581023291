<template>
  <div class="campaigns">
    <b-row>
      
      <b-col cols="4">
        <b-card title="Adlink reports">
          <hr>
          <router-link :to="{ name: 'adlinkgeneralreport' }">
            <b-button variant="outline-primary">Graph</b-button>
          </router-link>
          <router-link :to="{ name: 'adlinkgeneralreportpivot' }">
            <b-button variant="outline-primary">Performance Breakdown</b-button>
          </router-link>
          <router-link :to="{ name: 'shortlinkreport' }">
            <b-button variant="outline-primary">Shortlink Report</b-button>
          </router-link>
        </b-card>
      </b-col>

      <b-col cols="4">
        <b-card title="Cube reports">
          <hr>
          <router-link :to="{ name: 'fixedgeneralreport' }">
            <b-button variant="outline-primary">General</b-button>
          </router-link>
        </b-card>
      </b-col>

      <b-col v-if="level === 1 || level === 3" cols="4">
        <b-card title="Finance reports">
          <hr>
          <router-link :to="{ name: 'financereports' }">
            <b-button variant="outline-primary">General</b-button>
          </router-link>
        </b-card>
      </b-col>

    </b-row>
  </div>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    name: "cubecampaigns",
    components: {
    },
    computed: {
      ...mapState(['level'])
    }
  };
</script>