<template>
  <b-card>
    <div slot="header">
        Profiles
        <b-button 
          v-if="showButton && view == 'listprofiles' || showButton && view == 'manageuser' " 
          @click="addProfileToUser()" 
          size="sm" 
          variant="success"
          class="card-header-btns"
        >
          {{ buttonText }}
        </b-button>

        <b-button 
          v-if="showButton && view == 'createmicrocampaign'"  
          @click="addProfileToCampaign()" 
          size="sm" 
          variant="success"
          class="card-header-btns"
        >
          {{ buttonText }}
        </b-button>

         <b-button 
          v-if="showButton && view == 'createmicrocampaign'"  
          @click="removeProfileFromCampaign()" 
          size="sm" 
          variant="danger"
          class="card-header-btns"
        >
          Remove from campaign
        </b-button>

    </div>
    <div class="all-users">
      <b-row>
        <b-col class="table_filter" cols="12">
          <b-input-group>
            <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col cols="12" v-if="view == 'listprofiles'">

          <b-table 
            striped
            hover 
            responsive
            :items="this.level === 1 ? items : profile_assets" 
            :fields="fields"
            :filter="filter"
            @filtered="onFiltered"
          >
            <template v-slot:cell(type)="row">
              Profile
            </template>
            <template v-slot:cell(name)="row">
              <b-img 
                class="table-img"
                fluid 
                :src="getProfileImg(level === 1 ? row.item.id : row.item.profile_id)" 
                @error="replaceByDefault"
              ></b-img>
              {{ row.item.name }}
            </template>
            <template v-slot:cell(tune_id)="row">
             <span v-if="row.item.tune_id == 0">Disconnected</span>
             <span v-if="row.item.tune_id != 0">Connected</span>
            </template>
            <template v-slot:cell(id)="row">
                <router-link :to="{ name: 'manageasset', query: { id: level === 1 ? row.item.id : row.item.profile_id, type: 1 }}">
                  <b-button size="sm" variant="outline-primary">Edit Asset</b-button>
                </router-link>
            </template>
            </b-table>
          </b-col>

          <b-col cols="12" v-if="view == 'createmicrocampaign'">
          <b-table 
            striped
            selectable
            hover 
            :items="items" 
            :fields="fields_createmicrocampaign"
            :filter="filter"
            @filtered="onFiltered"
            @row-selected="rowSelectedMicro"
            select-mode="range"
            selectedVariant="success"
            ref="microprofiles"
          >
            <template v-slot:cell(type)="row">
              Profile
            </template>
            <template v-slot:cell(activated)="row">
                 {{row.selected}}
                <font-awesome-icon icon="badge-check" v-if="checkIfMicroOwnedByUser(row.item.id)" />
              </template>
            </b-table>
          </b-col>

          <b-col class="manageusertable" cols="12" v-else-if="view == 'manageuser'">
            <b-table 
              striped
              selectable
              select-mode="range"
              selectedVariant="success"
              :items="items" 
              :fields="fields_manage_user"
              thead-class="hidden_header"
              :filter="filter"
              @filtered="onFiltered"
              @row-selected="rowSelected"
              ref="allprofiles"
            >
              <template v-slot:cell(activated)="row">
                 {{row.selected}}
                <font-awesome-icon icon="badge-check" v-if="checkIfOwnedByUser(row.item.id)" />
              </template>
            </b-table>
          </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>

import { mapState } from 'vuex'

export default {
  name: "listassets",
  props:['view', 'buttonText', 'micro_profiles_from_listview'],
  components: {
  },
  created () {
    this.listProfiles()
  },
  data() {
    return {
      id: this.$route.query.id,
      error: false,
      success: false,
      users: null,
      filter: null,
      showButton: false,
      selected_profiles: [],
      activated_profiles: [],
      activated_profiles_micro: [],
      selected_profiles_micro: [],
      // Note `isActive` is left out and will not appear in the rendered table
      fields: [
        {
          key: 'name',
          sortable: true
        },
        {
          key: 'tune_id',
          sortable: true,
          label: 'Adlinks'
        },
        {
          key: 'id',
          label: 'Edit'
        }
      ],
      fields_createmicrocampaign: [
        {
          key: 'name',
          sortable: true
        },
        {
          key: 'activated',
          label: false,
          sortable: true
        }
      ],
      fields_manage_user: [
        {
          key: 'name',
          sortable: false,
          label: false
        },
        {
          key: 'activated',
          label: false,
        }
      ],
      items: [],
    }
  },
  computed: {
    ...mapState(['profile_assets', 'ext_profile_assets', 'name', 'brand_assets', 'level', 'type', 'current_id']),
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key }
        })
    }
  },
  methods: {
    getProfileImg(id){
      return this.$global.spacesURL()+'profiles/profile_' + id + '/profile_picture/profile_' + id + '.png'
    },
    replaceByDefault(e){
        var url = this.$global.spacesURL()+"placeholders/profile-placeholder.png"
        e.target.src = url
    },
    listProfiles() {
      this.$http.bamse.get('assets/profile/listall')
        .then(response => this.listProfilesInfoSuccess(response))
        .catch(() => this.listProfilesInfoFailed())
    },
    listProfilesInfoSuccess(response) {
      if(response.data.success) {
        this.items = response.data.success
        
        var i;
        for(i = 1; i <= response.data.success.length; i++){
          var check = this.ext_profile_assets.find(x => x.profile_id === response.data.success[i-1].id);
          if(check != undefined){
            this.activated_profiles.push(response.data.success[i-1].id);
          }
        }
        
      }
    },
    listProfilesInfoFailed() {
      this.error = 'no fetch sorry'
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    checkIfOwnedByUser(id) {
      var check = this.activated_profiles.includes(id);
      if(check == true){
        return true
      } else {
        return false
      }
    },
    checkIfMicroOwnedByUser(id) {
      var check = this.activated_profiles_micro.includes(id);
      if(check == true){
        return true
      } else {
        return false
      }
    },
    rowSelected(items) {
      this.selected_profiles = items
      if (this.selected_profiles && this.selected_profiles.length) {
        this.showButton = true
      } else {
        this.showButton = false
      }
    },
    rowSelectedMicro(items) {
      this.selected_profiles_micro = items
      if (this.selected_profiles_micro && this.selected_profiles_micro.length) {
        this.showButton = true
      } else {
        this.showButton = false
      }
    },
    addProfileToUser() {
      if(this.selected_profiles.length > 0) {
        var i;
        var id_array = []
        for(i = 1; i <= this.selected_profiles.length; i++){
          id_array.push({id: this.selected_profiles[i-1].id});
        }
        this.$http.bamse.post('assets/profile/connect', { 
          user_id: this.id, 
          profile_id: id_array
        })
          .then(response => this.addProfileToUserSuccess(response))
          .catch(() => this.addProfileToUserFailed())
      }
    },
    addProfileToUserSuccess(response) {
      if(response.data.success) {
        this.success = true
        var i;
        for(i = 1; i <= this.selected_profiles.length; i++){
          var item = this.selected_profiles[i-1].id;
          var check = this.activated_profiles.includes(item);
          if(check == true){
            var index = this.activated_profiles.indexOf(item);
            if (index !== -1) this.activated_profiles.splice(index, 1);

            if (this.id == this.current_id) {
              if (index !== -1) this.profile_assets.splice(index, 1);
            } else {
              if (index !== -1) this.ext_profile_assets.splice(index, 1);
            }

            
          } else {
            this.activated_profiles.push(this.selected_profiles[i-1].id);

            if (this.id == this.current_id) {
              this.profile_assets.push(
                {
                  profile_id: this.selected_profiles[i-1].id,
                  name: this.selected_profiles[i-1].name
                }
              )
            } else {
              this.ext_profile_assets.push(
                {
                  profile_id: this.selected_profiles[i-1].id,
                  name: this.selected_profiles[i-1].name
                }
              )
            }

            
          }
        }

        this.$refs.allprofiles.clearSelected()
        this.$global.makeToast(this, 'Success', 'Profiles updated', 'success')

      }
    },
    addProfileToUserFailed() {
      this.error = true
    },
    addProfileToCampaign() {

      for(var i = 1; i <= this.selected_profiles_micro.length; i++){
        this.activated_profiles_micro.push(this.selected_profiles_micro[i-1].id);
      }

      var uniq = [...new Set(this.activated_profiles_micro)];

      this.activated_profiles_micro = uniq;

      var id_array = [];

      for(var i = 1; i <= this.activated_profiles_micro.length; i++){
        id_array.push({id: this.activated_profiles_micro[i-1]});
      }

      this.$emit('update:micro_profiles_from_listview', id_array)

      this.$refs.microprofiles.clearSelected()
    },
    removeProfileFromCampaign() {

      var active = this.activated_profiles_micro;

      this.selected_profiles_micro.forEach(function(element) {
        var index = active.indexOf(element.id);
        if (index > -1) {
          active.splice(index, 1);
        }
      });

      var id_array = [];

      for(var i = 1; i <= this.activated_profiles_micro.length; i++){
        id_array.push({id: this.activated_profiles_micro[i-1]});
      }

      this.$emit('update:micro_profiles_from_listview', id_array)

      this.$refs.microprofiles.clearSelected()
    }
  },
};
</script>