<template>
  <div class="dashboard cube-dashboard">
	<b-row>
    <b-card-group columns>
      <b-card class="name-card">
        <b-card-text>
          <div class="pull-left">
            <b-img 
              fluid
              class="profile_pic"
              :src="this.$global.spacesURL()+'users/user_' + current_id + '/profile_image/user_' + current_id + '.png'" 
              @error="replaceByDefault"
            ></b-img>
          </div>
          <div class="pull-left name">
            <h4>Hi {{ name.substring(0, name.indexOf(' ')) }}</h4>
          </div>
          <div class="clearfix"></div>
        </b-card-text>
        <router-link :to="{ name: 'manageuser', query: { id: current_id }}">
          <b-button block variant="outline-primary">Edit your settings</b-button>
        </router-link>
      </b-card>

      <b-card class="calendar">
        <div>
          <h4 class="header-h">Calendar</h4>
          <span class="header-icon">
            <font-awesome-icon :icon="{ prefix: 'fal', iconName: 'calendar-alt' }"/>
          </span>
        </div>
        <div class="clearfix"></div>
        <hr>
        <v-calendar 
          :attributes='attrs'
          is-expanded 
          is-inline
          :key="refresh"
          popoverExpanded=true
          color="gray"
          title-position="right"
          :min-date='new Date()'
          locale="en"
          :first-day-of-week="2"
        >
          <div slot="day-popover" slot-scope="{ day, format, masks, dayTitle, attributes }">
            <div v-for="value in getTitleForDate(format(day.date, 'YYYY-MM-DD'))">
              <span v-if="value.type === 'Draft'" class="draft"></span>
              <span v-if="value.type === 'Publish'" class="publish"></span>
              <span v-if="value.type === 'Event' || value.type === 'Production'" class="event"></span>
              <router-link :to="{ name: 'managefixedcampaign', query: { id: value.campaign_id }}">
                {{ value.title }}
              </router-link>
            </div>
          </div>
        </v-calendar>
        <router-link :to="{ name: 'calendar' }">
          <b-button block variant="outline-primary">Go to calendar</b-button>
        </router-link>
      </b-card>

      <b-card>
        <div class="header-table">
          <h4 class="header-h">Your profiles</h4>
          <span class="header-icon">
            <font-awesome-icon :icon="{ prefix: 'fal', iconName: 'user' }"/>
          </span>
        </div>
        <div class="clearfix"></div>
        <b-col class="table_filter dashboard_filter" cols="12">
          <b-input-group>
            <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-table 
          striped
          :items="profile_assets" 
          :fields="fields"
          perPage=3
          thead-class="hidden_header"
          :filter="filter"
          @filtered="onFiltered"
        >
        <span slot="name" slot-scope="row">
          <b-img 
            fluid
            class="table-img"
            :src="getProfileImg(row.item.profile_id)" 
            @error="replaceByDefault"
          ></b-img>
          {{ row.item.name }}
        </span>
        <span slot="action" slot-scope="row">
          <router-link :to="{ name: 'manageasset', query: { id: row.item.profile_id, type: 1 }}">
            <b-button class="pull-right" size="sm" variant="outline-primary">Edit</b-button>
          </router-link>
        </span>
        </b-table>
          <span class="label_center">You have {{ profile_assets.length }} profiles</span>
          <router-link :to="{ name: 'listassets', query: { type: 'profiles' }}">
            <b-button block variant="outline-primary">View all</b-button>
          </router-link>
      </b-card>

      <b-card>
        <div class="header-table">
          <h4 class="header-h">Your brands</h4>
          <span class="header-icon">
            <font-awesome-icon :icon="{ prefix: 'far', iconName: 'copyright' }"/>
          </span>
        </div>
        <div class="clearfix"></div>
        <b-col class="table_filter dashboard_filter" cols="12">
          <b-input-group>
            <b-form-input v-model="filter_brands" placeholder="Type to Search"></b-form-input>
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter_brands = ''">Clear</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-table 
          striped
          :items="brand_assets" 
          :fields="fields_brands"
          perPage=3
          thead-class="hidden_header"
          :filter="filter_brands"
          @filtered="onFilteredBrands"
        >
        <span slot="company_name" slot-scope="row">
          <b-img 
            class="table-img"
            fluid 
            :src="getBrandImg(row.item.brand_id)" 
            @error="replaceByDefault"
          ></b-img>
          {{ row.item.company_name }}
        </span>
        <span slot="action" slot-scope="row">
          <router-link :to="{ name: 'manageasset', query: { id: row.item.brand_id, type: 2 }}">
            <b-button class="pull-right" size="sm" variant="outline-primary">Edit</b-button>
          </router-link>
        </span>
        </b-table>
          <span class="label_center">You have {{ brand_assets.length }} brands</span>
          <router-link :to="{ name: 'listassets', query: { type: 'brands' }}">
            <b-button block variant="outline-primary">View all</b-button>
          </router-link>
      </b-card>

      <b-card>
        <div class="header-table">
          <h4 class="header-h">Edit your campaigns</h4>
          <span class="header-icon">
            <font-awesome-icon :icon="{ prefix: 'fal', iconName: 'user-check' }"/>
          </span>
        </div>
        <div class="clearfix"></div>
        <b-col class="table_filter dashboard_filter" cols="12">
          <b-input-group>
            <b-form-input v-model="filter_campaigns_pm" placeholder="Type to Search"></b-form-input>
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter_campaigns_pm = ''">Clear</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-table 
          striped
          :items="items_campaigns_pm" 
          :fields="fields_campaigns_pm"
          perPage=3
          thead-class="hidden_header"
          :filter="filter_campaigns_pm"
          @filtered="onFilteredCampaignsPm"
        >
        <span slot="action" slot-scope="row">
          <router-link :to="{ name: 'managecampaign', query: { id: row.item.id }}">
            <b-button class="pull-right" size="sm" variant="outline-primary">Edit</b-button>
          </router-link>
        </span>
        </b-table>
          <span class="label_center">You have {{ items_campaigns_pm.length }} campaigns to edit</span>
          <router-link :to="{ name: 'listmicrocampaigns', query: { type: 'active' }}">
            <b-button block variant="outline-primary">View all</b-button>
          </router-link>
      </b-card>

      <b-card>
        <div class="header-table">
          <h4 class="header-h">Follow your campaigns</h4>
          <span class="header-icon">
            <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'dollar-sign' }"/>
          </span>
        </div>
        <div class="clearfix"></div>
        <b-col class="table_filter dashboard_filter" cols="12">
          <b-input-group>
            <b-form-input v-model="filter_campaigns_sales" placeholder="Type to Search"></b-form-input>
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter_campaigns_sales = ''">Clear</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-table 
          striped
          :items="items_campaigns_sales" 
          :fields="fields_campaigns_sales"
          perPage=3
          thead-class="hidden_header"
          :filter="filter_campaigns_sales"
          @filtered="onFilteredCampaignsSales"
        >
        <span slot="campaign_budget" slot-scope="row">
          {{row.item.campaign_budget}} SEK
        </span>
        </b-table>
          <span class="label_center">You have sold {{ items_campaigns_sales.length }} active campaigns</span>
          <router-link :to="{ name: 'soldcampaigns'}">
            <b-button block variant="outline-primary">View all</b-button>
          </router-link>
      </b-card>

    </b-card-group>

      
<!--
      <b-col cols="6">
        <b-card title="Your Profiles">
          <b-card-text>
            Manage your profiles.
          </b-card-text>
			<b-list-group flush>
        <b-list-group-item v-for="item in profile_assets">
          {{ item.name }}
          <router-link :to="{ name: 'manageasset', query: { id: item.profile_id, type: 1 }}">
            <b-button class="pull-right" size="sm" variant="dark">Manage</b-button>
          </router-link>
        </b-list-group-item>
			</b-list-group>
        </b-card>
      </b-col>
      <b-col cols="6">
        <b-card title="Your Brands">
          <b-card-text>
            Manage your brands.
          </b-card-text>
          <b-list-group flush>
            <b-list-group-item v-for="item in brand_assets">
              {{ item.company_name }}
              <router-link :to="{ name: 'manageasset', query: { id: item.brand_id, type: 2 }}">
                <b-button class="pull-right" size="sm" variant="dark">Manage</b-button>
              </router-link>
            </b-list-group-item>
      </b-list-group>
        </b-card>
      </b-col> -->
    </b-row>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import FileUpload from "@/components/general/FileUpload.vue";

export default {
  name: "dashboard",
  components: {
    FileUpload
  },
  computed: {
    ...mapState(['name', 'country', 'current_id', 'profile_assets', 'brand_assets']),
    sortOptions() {
        // Create an options list from our fields
        return this.fields
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key }
          })
      },
      sortOptionsBrands() {
        // Create an options list from our fields
        return this.fields_brands
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key }
          })
      },
      sortOptionsCampaignPm() {
        // Create an options list from our fields
        return this.fields_campaign_pm
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key }
          })
      },
      sortOptionsCampaignSales() {
        // Create an options list from our fields
        return this.fields_campaigns_sales
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key }
          })
      }
  },
  data: function () {
    return {
      calendaritems: [],
      refresh: false,
      file: null,
      image_name: '',
      image: '',
      filter: null,
      filter_brands: null,
      filter_campaigns_pm: null,
      items_campaigns_pm: [],
      filter_campaigns_sales: null,
      items_campaigns_sales: [],
      fields_campaigns_pm: [
        {
          key: 'campaign_name',
        },
        {
          key: 'action',
        }
      ],
      fields_campaigns_sales: [
        {
          key: 'campaign_name',
        },
        {
          key: 'campaign_budget',
        }
      ],
      fields: [
        {
          key: 'name',
        },
        {
          key: 'action',
        }
      ],
      fields_brands: [
        {
          key: 'company_name',
        },
        {
          key: 'action',
        }
      ],
      attrs: [
      {
        key: 'today',
        highlight: true,
        dates: [],
        customData: {
          names: []
        },
        popover: { 
          label: true,
          isInteractive: true,
        }
      }
    ]
    }
  },
  created () {
    this.getCalendarPosts(),
    this.listMicroCampaignsPm(),
    this.listMicroCampaignsSales()
  },
  methods: {
    getBrandImg(brand_id){
      return this.$global.spacesURL()+'brands/brand_' + brand_id + '/profile_picture/brand_' + brand_id + '.png'
    },
    getProfileImg(profile_id){
      return this.$global.spacesURL()+'profiles/profile_' + profile_id + '/profile_picture/profile_' + profile_id + '.png'
    },
    replaceByDefault(e){
        var url = this.$global.spacesURL()+"placeholders/profile-placeholder.png"
        e.target.src = url
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    onFilteredBrands(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    onFilteredCampaignsPm(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    onFilteredCampaignsSales(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    getCalendarPosts() {
      this.$http.bamse.get('campaigns/fixed/cube/listalldates')
        .then(response => this.getCalendarPostsSuccess(response))
        .catch(() => this.getCalendarPostsFailed())
    },
    getCalendarPostsSuccess(response) {
      if(response.data.success) {

        //Events
        response.data.success.events.forEach((item, index) => {

          this.attrs[0].customData.names.push({
              date: item.end_date, 
              title: item.campaign_name+': '+item.title+' ('+item.type+')',
              campaign_id: item.campaign_id,
              type: item.type
          });

          this.attrs[0].dates.push(item.end_date);

        })

        //Profile activities
        response.data.success.profile_activities.forEach((item, index) => {
          this.attrs[0].customData.names.push({
              date: item.draft_date, 
              title: item.campaign_name+': '+item.activity+' (Draft)',
              campaign_id: item.campaign_id,
              type: 'Draft'
          });

          this.attrs[0].customData.names.push({
              date: item.publish_date, 
              title: item.campaign_name+': '+item.activity+' (Publish)',
              campaign_id: item.campaign_id,
              type: 'Publish'
          });

          this.attrs[0].dates.push(item.draft_date);
          this.attrs[0].dates.push(item.publish_date);

        })
        
        this.refresh = true

      }
    
    },
    getCalendarPostsFailed() {
      this.error = 'no fetch sorry'
    },
    listMicroCampaignsPm() {
      this.$http.bamse.get('user/campaigns/projectmanager')
        .then(response => this.listMicroCampaignsPmSuccess(response))
        .catch(() => this.listMicroCampaignsPmFailed())
    },
    listMicroCampaignsPmSuccess(response) {
      if(response.data.success) {
        this.items_campaigns_pm = response.data.success
      }
    },
    listMicroCampaignsPmFailed() {
      this.error = 'no fetch sorry'
    },
    listMicroCampaignsSales() {
      this.$http.bamse.get('user/campaigns/salesperson')
        .then(response => this.listMicroCampaignsSalesSuccess(response))
        .catch(() => this.listMicroCampaignsSalesFailed())
    },
    listMicroCampaignsSalesSuccess(response) {
      if(response.data.success) {
        this.items_campaigns_sales = response.data.success
      }
    },
    listMicroCampaignsSalesFailed() {
      this.error = 'no fetch sorry'
    },
    getTitleForDate(datum) {
      var title_array = [];
      var checkDate = this.attrs[0].customData.names;
      checkDate.forEach((item) => {
        if(item.date === datum) {
          title_array.push({
            title: item.title, 
            campaign_id: item.campaign_id,
            type: item.type
          })
        }
      })

      return title_array
    },
    getCampaingIdForDate(datum) {
      var campaignID_array = [];
      var checkDate = this.attrs[0].customData.names;
      checkDate.forEach((item) => {
        if(item.date === datum) {
          campaignID_array.push(item.campaign_id)
        }
      })

      return campaignID_array
    }
  }
};
</script>
