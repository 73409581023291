<template>
  <div>
    <b-modal v-model="modalShow" hide-footer hide-header size="lg" id="toc-modal" :no-close-on-esc="true" :no-close-on-backdrop="true">
      <div class="d-block text-left">
        <h2>Hi {{ name }},<br /> Welcome to Revrelations powered by Cube!</h2>
      </div>
      <div class="d-block text-left">
        <p>Please read through our terms and policies before you start using Revrelations platform!</p>
        <p>The terms and policies are written in English to accommodate international users. Please let us know if you need help to clarify any of the writing. Please email us at support@revrelations.com.</p>
      </div>
      <object data="https://bamse-in-space.fra1.digitaloceanspaces.com/live/toc.pdf" type="application/pdf" width="100%" height="100%" hspace="0">
        This browser does not support PDFs.
      </object>
      <b-button variant="outline-primary" block @click="getTocStatus">I agree to terms</b-button>
    </b-modal>
  </div>
</template>

<script>
import { truncate } from 'fs';
import { mapState } from 'vuex'

export default {
  name: "TermsAndConditions",
  data: function () {
    return {
      modalShow: false,
      step1: true,
      step2: false,
    }
  },
  computed: {
    ...mapState(['name']),
  },
  created () {
    this.toggleModal()
    this.addLocalModalFalse()
  },
  methods: {
    toggleModal() {
      this.modalShow = !this.modalShow
      localStorage.tocRead = true
    },
    addLocalModalFalse() {
      localStorage.tocRead = false
    },
    readTerms() {
      this.step1 = !this.step1;
      this.step2 = !this.step2;
    },
    getTocStatus() {
      this.$http.bamse.get('user/toc/accept')
        .then(response => this.getTocStatusSuccess(response))
        .catch(() => this.getTocStatusFailed(response))
    },
    getTocStatusSuccess(response) {
      this.toggleModal()
    },
    getTocStatusFailed(response) {
      console.log(response)
    }
  },
};
</script>